
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import "https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css";
@import "https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap-theme.min.css";
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
body {
  width: 100%;
  font-family: "Poppins", sans-serif !important;
  height: 100%;
}
ul li {
  list-style-type: none;
}
a {
  text-decoration: none !important;
}
/* .owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next, .owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
  display: none;
  visibility: hidden;
} */

/****************** Header top ********************/
.navbar {
  margin-bottom: 0px;
}
.section-div-right {
  display: block;
}
.heading-section-h1 h1 {
  margin: 10px 0px;
  padding: 20px 0px;
  color: white;
  font-size: 30px;
  font-weight: 700;
}
.header-top {
  position: relative;
  background-color: black;
}

.header-section-one {
  width: 100%;
}

.right-div-section {
  width: 100%;
  height: 86px;
}

.header-top-wrapper .video-section {
  height: 86px;
}
.header-top-wrapper .video-section .video-div {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* .logo-soundchat {
  width: 32%;
} */
.logo-soundchat img {
  width: 85px;
  height: 85px;
 
}
.logo-text-heading {
  width: 100%;
  color: white;
  padding-left: 2px;
  background-color: #3d3939;
}
.logo-text-heading p {
  margin-bottom: -5px;
  font-size: 13px;
}
.recap-video-heading.recap-d1 {
  position: absolute;
  box-shadow: inset 587px 4px 82px -66px #000000c2 !important;
}
.bottom-pera {
  color: #ffc107;
  font-size: 14px;
  padding-right: 9px;
}
.div-h6 {
  margin-bottom: 0px;
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
}

.bg-color {
  background-color: #000;
}
/****************** HEADER BO***************/
.header-menu-2 {
  display: none;
}
.header-bottam {
  position: sticky;
  width: 100%;
  background: #000;
  top: 0px;
  z-index: 1024;
}
.section-div-right-1 {
  display: none;
}
a.header-menu-1 {
  font-size: 21px !important;
  color: #fff !important;
  margin-right: 13px !important;
}

.header-menu-1-div{
  font-size: 23px !important;
  color: #fff !important;
  margin-right: 13px !important;
  /* display: none; */
}

h1.header-coutong {
  font-size: 12px;
  color: #000;
  width: 17px;
  padding: 2px 5px 0px 5px;
  height: 17px;
  border-radius: 50%;
  background: #ffc107;
  position: absolute;
  top: 4px;
  right: 16px;
  /* left: -1px; */
}
a.header-menu{
  padding: 5px 10px;
  color: #ffc107 !important;
  text-decoration: none;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
}

.header-menu-0 li {
  margin-top: 8px;
  color: #fff;
}
.header-menu:hover {
  color: #FFF !important;
  border-bottom: 1px solid;
}

.navbar-collapse{
  padding-right: 3px;
  padding-left: 9px;
}
a.header-humger {
  width: 30px;
  padding: 3px 0px 0px 8px;
  height: 30px;
  font-size: 15px;
  color: #000;
  background: #fff;
  border-radius: 50%;
}
.color1 {
  background-color: rgb(29 161 242);
}
.color2 {
  background-color: rgb(255 0 0);
}
.color3 {
  background-color: rgb(5 10 230);
}
.color4 {
  background-color: rgb(66 103 178);
}

.social-icon-div {
  display: flex;
}
.social-icon-div li {
  color: white;
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin: 3px;
}
.pointer {
  cursor: pointer;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  font-size: 13px !important;
}
/***************************** layout section ********************/
.Soundchat-div {
  padding: 0px 8px 0px;
}
.video-soundchat {
  border: 8px solid #fff;
  position: relative;
  border-radius: 20px;
  padding: 6px 0px;
}
.video-soundchat img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}
/* .chat-user {
  height: 517px;
  background-image: linear-gradient(180deg, #3e030e, #321a2ee6);
  border-radius: 5px;
  text-align: center;
  padding-top: 130px;
  padding: 10px;
} */

/* .chat-user {
  height: 462px;
  background-image: linear-gradient(180deg, #3e030e, #321a2ee6);
  border-radius: 5px;
  text-align: center;
  padding-top: 130px;
} */

/* .btn-div-chat-login {
  width: 100%;
  font-weight: 600;
  padding: 9px 0px;
  border: none;
  background-color: #808080a3;
  color: white;
  border-radius: 5px;
  font-size: 14px;
} */

/* .has-search input {
  width: 100%;
  height: 49px;
  border-radius: 26px;
  padding-left: 6.375rem;
  border: 2px solid white;
  font-size: 13px;
  background-color: transparent;
  color: white !important;
} */

/* .div-or {
  color: white;
  font-size: 17px;
} */

/* .has-search .user-icon-div {
  position: absolute;
  margin-top: 2px;
  left: 20px !important;
  font-size: 26px;
  border-radius: 50%;
  background-color: #c59ca0;
  width: 45px;
  height: 45px;
  color: #813a41;

} */
.react-photo-gallery--gallery img {
  height: auto;
max-width: 100%;
  object-fit: cover;
}
/************************ PODCAST SECTION START *****************/
.slider-section {
  padding: 6px 0px;
}
.podcast-heading h4{
  text-align: left;
  color: white;
  font-size: 18px;
  padding: 16px 0px;
}
.show-days-div {
  margin: 20px 0px 46px 0px;
}
.show-day h5{
  color: white;
  font-size: 16px;
}
.podcast-vo-div{
  width: 100% !important;
  margin: 15px 0px 0px 0px;
  border: 2px solid #ffc107;
  border-radius: 17px;
  line-height: 8px;
}
.podcast-bo-box {
  padding: 6px;
  text-align: left;
  border-radius: 0px 0px 14px 14px;
}
.podcast-bo-box > h6 {
  font-size: 14px;
  color: #ffc107;
  font-weight: 500;
}
.podcast-bo-box > p {
  color: white;
  font-size: 14px;
    font-weight: 300;
}



.heading-h4-div h4 {
  margin-top: 5px;
  margin-bottom: 17px;
  font-size: 19px;
  font-weight: 600;
  color: #1e1e1e;
}
/* .podcast-box {
  margin-bottom: 42px;
} */
.podcast-bottam>.nav-tabs{
  background-color: #ffffff;
}

.podcast-bottam .nav-tabs .nav-link.active {
  border-left: none;
  border-right: none;
  border-top: none;
  color: #dc3545 !important;
  border-bottom: 2px solid #dc3545;
}
/* .podcast-bottam .nav-tabs:focus {
  display: none;
} */
.podcast-bottam>.nav-tabs .nav-item {
  padding: 13px;
  font-size: 16px;
  font-weight: 500;
  margin: auto;
  text-align: center;
  color: #000!important;
  background-color: transparent;
}
.table-responsive {
  border-collapse: collapse;
  overflow-x: auto;
  display: block;
  width: fit-content;
  max-width: 100%;
}
.heading-h4-podcast h4 {
  margin-top: 5px;
  margin-bottom: 17px;
  font-size: 19px;
  font-weight: 600;
  color: white;
}
.podcast-img img {
  width: 127px !important;
  border-radius: 50%;
  height: 127px;
  margin: 0 auto;
  border: 4px solid #fff;
  position: relative;
}

.podcast-box-pod h6 {
  color: white;
  text-align: center;
  font-size: 13px;
  padding-top: 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.podcast-img-div img {
  width: 120px !important;
  border-radius: 50% !important;
  height: 120px !important;
  margin: 0 auto !important;
  border: 4px solid #fff !important;
}
.pera-podcast {
  text-align: center;
  line-height: 0px;
  font-size: 13px;
  color: #ffc107;
}
.podcast-bottam-div {
  width: 100%;
  display: flex;
  margin-bottom: 18px;
  margin-top: 10px;
}
.podcast-bottam-head {
  width: 19%;
  background-color: #d71a1a;
  color: white;
  padding: 4px 9px;
}
.podcast-bottam-head > h5 {
  text-align: left;
  font-size: 20px;
  padding: 10px 0px;
  margin-bottom: 0px;
}
.podcast-bottam-head-div {
  width: 85%;
  background-color: #504545d1;
  color: white;
  padding: 13px 17px;
}
.podcast-bottam-head-div > h5 {
  font-size: 20px;
}
/************************ PODCAST SECTION END *****************/

/************************ NEWS SECTION START *****************/

.swiper {
  width: 100%;
  height: 100%;
}
.head>h2{
  font-weight: 400;
    color: white;
    padding: 0px 0px 10px 0px;
    font-size: 20px;
    margin-bottom: 0px;
}

.swiper-slide {
  font-size: 18px;
  background: #fff;
}
.product-section {
  width: 100%;
  padding: 0px 15px;
}
.content {
  position: absolute !important;
  bottom: 0 !important;
  color: #f1f1f1 !important;
  width: 100% !important;
  height: 182px !important;
  box-shadow: inset 0 -70px 9px 8px #000000c2 !important;
  padding-top: 120px !important;
}

.swiper-backface-hidden .swiper-slide {
  transform: translateZ(0);
  backface-visibility: hidden;
  border-radius: 25px;
  background: transparent;
}
horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: var(--swiper-pagination-bottom, 8px);
  top: var(--swiper-pagination-top, auto);
  left: 0;
  width: 100%;
  visibility: hidden;
}
.cover-text.slide.animate-text {
  visibility: hidden;
}
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 4px);
  visibility: hidden;
  display: none;
}
.news-video-section .react-player__preview {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-radius: 25px 25px 0px 0px;
  justify-content: center;
}
.icon-news-div {
  color: #ffc107;
  text-align: center;
}

.news-video-icn {
    position: relative;
    display: block;
    margin: 0 auto;
    text-align: center;
    align-items: baseline;
    font-size: 26px;
    /* bottom: 6px; */
    border: 4px solid #fff;
    width: 50px;
    cursor: pointer;
    top: 87px;
    height: 50px;
    border-radius: 50%;
}
.day-div p {
  font-size: 11px !important;
  padding: 1px 14px;
  margin-bottom: 2px;
  color: #fff !important;
}
.div-news-h6 {
  color: #ffc107;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
  text-align: center;
}
.content>p {
  font-size: 13px;
  margin-bottom: 0px;
  line-height: 18px;
  font-weight: 400;
  text-align: center;
}
.news-video-section {
  width: 100%;
}

/* .news-gallery {
  height: 250px;
  cursor: pointer;
  position: relative;
} */
/* facebook images start*/
/* .grid-container {
  text-align: center;
  margin: auto;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.col-md-4 {
  width: 33.33333333% !important;
}
.border-rad {
  border-top-left-radius: 25px;
}
.border-rad-tow {
  border-top-right-radius: 25px;

} */

/* .day-div-view-story {
  color: #fff;
  background-color: #00000080;
  position: relative;
  top: 45px;
  left: 567px;
  z-index: 999;
  width: 108px;
  height: 26px;
  border-radius: 61px;
  text-align: center;
  padding-top: 5px;
} */

.grid-container {
  text-align: center;
  margin: auto;
  width: 100%;
  padding: 20px 8px 0px 23px;
  /* height: 500px; */
}

.container {
  padding-left: 0;
  padding-right: 0;
  width: 100% !important;
}
.ReactModal__Content{
    position: absolute;
    inset: 0px;
    border: none;
    background: transparent;
    overflow: hidden;
    border-radius: 0px;
    outline: none;
    padding: 0px;
    margin-top: 36px !important;
}
.container *{
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.col-md-4 {
  width: 33.33333333%;
}

.border {
  border: 2px solid white;
  border-radius: 6px;
}

.background {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  cursor: pointer;
}

.height-one {
  width: 100%;
  padding-top: 100%;
}

.height-two {
  width: 50%;
  padding-top: 50%;
}

.height-three {
  width: 33.3333%;
  padding-top: 33.3333%;
}

/* .cover {
  background-color: #222;
  opacity: 0.8;
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  border-radius: 6px;
} */

.cover-text {
  right: 0;
  left: 0;
  bottom: 0;
  color: white;
  font-size: 7%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  text-align: center;
}

.cover-text > p {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slide {
  height: 0;
  bottom: 100%;
  transition: .5s ease;
  overflow: hidden;
  font-size: 3%;
}

.border:hover .slide  {
  bottom: 0;
  height: auto;
}

.border:hover .animate-text  {
  top: 62%
}


/* facebook images endponit*/





/* .news-gallery {
  height: 250px;
} */
/************************ NEWS SECTION END *****************/
/************************ INTERVIEW SECTION STATR *****************/
.current-trn-section h5 {
  text-align: center;
  background: #1c1b1bc4;
  color: white;
  font-size: 22px;
  padding: 10px 0px;
  font-weight: 400;
}
.news-heading {

  display: flex;
  align-items: center;
  justify-content: space-between;
}
.news-heading .view-more-div {
  background-color: transparent;
  color: white;
  font-size: 15px;
}
.video-slider img{
  opacity: 87% !important;
  width: 100%;
  height: 400px;
  /* margin: auto;
  float: right;
  display: block; */
  object-fit: cover;
  background-position: center;
}
.recap-video-heading {
  position: absolute;
  box-shadow: inset 587px 4px 82px -66px #000000c2 !important;
  top: 0px;
  left: 0px;
  padding: 53px 36px 0px 19px;
  bottom: 0px;
  width: 593px;
  color: white;
  /* opacity: 52%; */
}
/************************ NEWS INTERVIEW SECTION STATR *****************/

/************************ FOOTER SECTION START *****************/
/* .listen-section-div{
  padding: 0px 5px 0px 5px;
} */
.live-podcast img{
  border-radius: 10px;
}
.listen-img-div {
  box-shadow: inset rgb(238 232 232 / 32%) 3px 0px 28px;
  height: 500px;
  width: 100%;
  background: #212529;
  /* padding: 9px; */
  border-radius: 20px;
}


ol,
ul {
  padding-left: 0px !important;
}
/* .bg_img {
  background-image: url("https://soundchatradio.com/wp-content/uploads/2021/04/sc-footer-bgn2.jpg");
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100% !important;
  height: 400px;
} */
.title {
  font-size: 18px;
  color: white;
  font-family: "Poppins", sans-serif;
 
}
.links-list li {
  font-family: "Poppins", sans-serif;
  margin: 15px 0px;
  font-size: 16px !important;
  color: white;
  text-align: left !important;
}
.button-div {
  width: 160px;
  cursor: pointer;
  margin: auto;
}
button.btn.btn-div-plya.d-flex {
  width: 175px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.btn-div-plya {
  border: 1px solid white !important;
  font-size: 15px !important;
  color: white !important;
 
}
button.btn.btn-div-plya.d-flex {
  width: 175px;
  height: 48px;
}
.btn-div-plya .google-div {
  width: 21px;
  display: block;
 
}
.google-div img {
  width: 100%;
}
.div-iphone {
  margin-top: 20px;

}


ul.links-list.margin-div {
  margin-left: 29px;
  margin-right: 31px;
  /* padding-left: 72px; */
}


.sc-div {
  width: 170px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-top: 15px;
}
.sc-div img {
  height: 100%;
  width: 100%;
}

.button-div img {
  width: 100%;
} 

/*******************recap tv shows section********************/
.interview-div {
  padding: 0px 20px 10px 20px;
}
.currently-main-div {
  padding: 0px 20px;
}
.view-more-sect-div{
  padding: 0px 10px;
}
.recap-div {
  padding: 20px 0px;
}
.slider-new {
  position: relative;
  width: 100%;
  height: 100%;
}
.video-player .react-player__play-icon{
  border-style: solid;
    border-width: 16px 0px 16px 26px;
    border-color: transparent transparent transparent white;
    margin-left: 7px;
    display: none !important;
}


.recap-video-heading .recap-video-h1 {
  font-size: 40px;
  font-weight: 800;
  margin-top: 10px;
  /* opacity: 52%; */
}
.recap-video-heading .recap-video-pra {
  font-size: 16px;
  font-weight: 400;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.recap-icon-w-div {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 36px;
  font-size: 14px;
  padding-left: 2px;
  height: 20px;
  border-radius: 50%;
  background-color: red;
  width: 20px;
  margin-top: 13px;
  color: #fff;
}
.video-recap-btn-div button {
  border-radius: 21px;
  letter-spacing: 1px;
  padding: 6px 32px;
  background-color: transparent;
  color: white;
  font-size: 18px;
  font-weight: 700;
  border: 2px solid white;
  margin-top: 15px;
}
.video-recap-btn-div button:hover{
  background-color: #fff;
  color: #000;
  box-shadow: 0px 2px 7px 1px #fff;
}


.video-recap-btn-div {
  display: flex;
  align-items: center;
}

.recap-video-heading .recap-div-p {
  font-size: 18px;
  background-color: #3d3939;
  padding: 5px 10px;
  font-weight: 400;
  border: none;
}
.img-recap img {
  object-fit: cover;
  width: 100% !important;
  height: 162px;
  border-radius: 15px 15px 0px 0px;
  cursor: pointer;
}
.show-section {
  padding: 20px;
}
.news-heading h5 {
  font-size: 20px;
  color: white;
  margin-bottom: 0px;
  font-weight: 500;
}
.heading-div-new {
  display: flex;
}
p.listen-second {
  text-align: left;
  margin-bottom: 0px;
  bottom: 0px;
  font-size: 13px;
  font-weight: 600;
  color: #ffc107;
}
/* .listen-btn-new {
  top: 62%;
  position: absolute;
  bottom: 0px;
  color: #000;
  border: 1px solid;
  width: 139px;
  text-align: center;
  border-radius: 20px;
  height: 34px;
  padding: 7px;
  font-size: 13px;
  margin-left: 7%;
} */
.slide-fed-new {
  /* background: #120e0e; */
  position: relative;
}
.fade-in-new img {
  width: 100%;
  height: 502px;
  object-fit: cover; 
}
p.listen-first {
  font-size: 12px;
  text-align: left;
  font-weight: 600;
  color: #ffc107;
  margin-bottom: -5px;
}
.reacp-listen{
  position: absolute;
  bottom: 0;
  color: #f1f1f1;
  padding: 0px 7px;
  width: 100%;
  height: 67px;
  background-color: #0000009c;
  border-radius: 0px;
}
h6.recap-listenn-pra {
  font-size: 14px;
  text-align: left;
  font-weight: 400;
  /* overflow: hidden; */
  margin-bottom: 0px;
  padding: 4px 0px 0px 0px;
}
.reacp-contnent {
  position: absolute;
  bottom: 0;
  color: #f1f1f1;
  width: 100%;
  height: 29px;
  background-color: #0000009c;
  border-radius: 0px;
  /* box-shadow: inset 0 -45px 0px 0px #0000009c; */
  /* padding-top: 81px; */
}

.reacp-contnent-home p.recap-pra {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.reacp-contnent-home {
  position: absolute;
  bottom: 0;
  color: #f1f1f1;
  width: 100%;
  line-height: 18px;
  height: 27px;
  text-align: center;
  font-size: 14px;
  background-color: #0000009c;
  border-radius: 0px;
  /* box-shadow: inset 0 -45px 0px 0px #0000009c; */
  padding-top: 5px;
}

.view-recap-more {
  position: relative;
  margin-bottom: 20px;
}
.view-recap-more img {
  position: relative;
  width: 100%;
  height: 260px;
  object-fit: cover;
  border-radius: 15px 15px 0px 0px;
}
.reacp-contnent .recap-pra {
  font-size: 13px;
  font-weight: 500;
  overflow: hidden;
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  margin-bottom: 0px;
  padding: 5px;
}
.heading-h5-div h5 {
  text-align: center;
  font-size: 14px;
  color: white;
  margin-bottom: 18px;
}


.news-heading h6 {
  color: #ffc107;
  font-size: 13px;
  margin-bottom: 0px;
}

.recap-one-pra {
  font-size: 13px;
  color: white;
  position: absolute;
  background: #00000060;
  height: 112px;
  width: 124px;
  bottom: 20px;
  left: 67px;

  box-shadow: -2px -35px 6px 20px #00000060;
}
.news-heading.bg-div-color {
 background-image: linear-gradient(73deg, #08560875, #0c710514); 
    padding: 7px 10px;
    margin: 20px 0px;
}
.heading-section-h5 {
  margin: 10px 0px;
}

.recap-p-div {
  margin-top: -24px;
}

/*************************** view more pages***********************/
.recap-view-more{
  text-align: center;
  position: absolute;
  height: 43px;
  bottom: 0;
  color: #f1f1f1;
  padding: 9px 0px;
  width: 100%;
  box-shadow: inset 0 -112px 13px -8px #000000a8;
  line-height: 17px;
}
.recap-view-more .recap-pra-view-more{
  font-size: 14px;
  color: white;
}

.recap-view-more .small-pra-view{
  font-size: 12px;
  color: white;
  padding-left: 12px !important;
}

.player-div-five .react-player__shadow {
  border: 2px solid white;
  background: radial-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 60%);
  border-radius: 64px;
  width: 43px !important;
  height: 43px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 88px;
  margin-right: 7px;
  z-index: 1;
}
.player-div-five .react-player__preview {
  width: 100% !important;
  height: 100% !important;
  background-size: cover !important;
  background-position: center center !important;
  cursor: pointer !important;
  display: flex !important;
  align-items: center !important;
  justify-content: right !important;
}
.player-div-five .react-player__play-icon {
  border-style: solid;
  border-width: 11px 0px 11px 18px !important;
  border-color: transparent transparent transparent white;
  margin-left: 7px;
}


/************ talk time **************/
.recap-seaven {
  position: absolute;
  bottom: 0px;
  width: 282px;
  height: 114px;
  box-shadow: inset 0 -148px 9px -8px #000000a8;
  text-align: center;
  color: white;
  font-size: 12px;
  padding: 8px 0px;
  font-weight: 500;
}
.recap-seaven-h6 {
  background-color: #ecebeb;
  color: #000;
  padding: 4px;
  font-weight: 700;
}
.reacp-contnent-video-seaven {
  position: absolute;
  bottom: 0;
  color: #f1f1f1;
  width: 282px;
  height: 49px;
  box-shadow: inset 0 -110px 13px -8px #000000a8;
}
.recap-pra-video-seaven {
  font-size: 20px;
  font-weight: 500;
  line-height: 0px;
  margin-top: 14px;
  margin-left: 12px;
}
.small-div-seaven {
  font-size: 12px;
  margin-left: 12px;
}
.recap-seaven-div {
  position: absolute;
  bottom: 0px;
  left: 106px;
  width: 224px;
  height: 140px;
  box-shadow: inset 0 -228px 9px -8px #000000a8;
  color: white;
  font-size: 12px;
  padding: 9px 0px;
  font-weight: 500;
}
.recap-seaven-div-seaven {
  position: absolute;
  bottom: 0px;
  left: 142px;
  width: 187px;
  height: 262px;
  box-shadow: inset 0 -275px 9px -8px #000000a8;
  color: white;
  font-size: 12px;
  padding: 71px 0px;
  font-weight: 500;
}

.recap-seaven-div-one {
  position: absolute;
  bottom: 0px;
  width: 323px;
  height: 77px;
  box-shadow: inset 0 -148px 9px -8px #000000a8;
  text-align: center;
  color: white;
  font-size: 12px;
  padding: 8px 0px;
  font-weight: 500;
}

.recap-seaven-div-one-one {
  position: absolute;
  right: 106px;
  bottom: 0px;
  width: 224px;
  height: 136px;
  box-shadow: inset 0 -148px 9px -8px #000000a8;
  text-align: right;
  color: white;
  font-size: 12px;
  padding: 8px 0px;
  font-weight: 500;
}

.recap-seaven-div-one-one-div {
  position: absolute;
  right: 106px;
  bottom: 0px;
  width: 223px;
  height: 258px;
  box-shadow: inset 0 -268px 9px -8px #000000a8;
  text-align: right;
  color: white;
  font-size: 12px;
  padding: 116px 0px;
  font-weight: 500;
}
.recap-seaven-recap-one {
  position: absolute;
  bottom: 0px;
  width: 322px;
  height: 147px;
  box-shadow: inset 0 -182px 9px -8px #000000a8;
  text-align: center;
  color: white;
  font-size: 12px;
  padding: 8px 0px;
  font-weight: 500;
}

.recap-seaven-recap-one-recap-one {
  position: absolute;
  bottom: 0px;
  width: 323px;
  height: 260px;
  box-shadow: inset 0 -272px 9px -8px #000000a8;
  text-align: center;
  color: white;
  font-size: 12px;
  padding: 130px 0px;
  font-weight: 500;
}
.recap-seaven-h6-div {
  background-color: #ecebeb;
  color: #000;
  padding: 4px 26px;
  font-weight: 700;
}

/********** mawnin vibes video section ***************/

.logo-video-heading h4 {
  margin-left: 50px;
  background-color: #670716;
  width: 400px;
  padding: 10px 0px;
  color: white;
}
.logo-video-section {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.logo-video {
  width: 52px;
  background-image: linear-gradient(95deg, #3f51b5, #670716);
  border-radius: 50%;
}

.logo-video img {
  width: 100%;
  display: flex;
  height: 50px;
  padding: 4px 10px;
  align-items: center;
  justify-content: center;
}

.recap-hea-video {
  text-align: center;
  color: white;
}
.video-recap-img img {
  width: 100%;
  height: 200px;
}
.recap-seaven-video-re {
  position: absolute;
  bottom: 0px;
  width: 204px;
  height: 114px;
  box-shadow: inset 0 -148px 9px -8px #000000a8;
  text-align: center;
  color: white;
  font-size: 12px;
  padding: 8px 0px;
  font-weight: 500;
}

.recap-seaven-div-rec {
  position: absolute;
  bottom: 0px;
  left: 48px;
  width: 158px;
  height: 140px;
  box-shadow: inset 0 -228px 9px -8px #000000a8;
  color: white;
  font-size: 12px;
  padding: 9px 0px;
  font-weight: 500;
}
.recap-seaven-div-seaven-rec-sev {
  position: absolute;
  bottom: 0px;
  left: 62px;
  width: 142px;
  height: 262px;
  box-shadow: inset 0 -275px 9px -8px #000000a8;
  color: white;
  font-size: 12px;
  padding: 71px 0px;
  font-weight: 500;
}
.reacp-contnent-video-seaven-rec {
  position: absolute;
  bottom: 0;
  color: #f1f1f1;
  width: 205px;
  height: 49px;
  box-shadow: inset 0 -110px 13px -8px #000000a8;
}
.recap-seaven-div-one-rec {
  position: absolute;
  bottom: 0px;
  width: 204px;
  height: 77px;
  box-shadow: inset 0 -148px 9px -8px #000000a8;
  text-align: center;
  color: white;
  font-size: 12px;
  padding: 8px 0px;
  font-weight: 500;
}
.recap-seaven-div-one-one-rec {
  position: absolute;
  right: 50px;
  bottom: 0px;
  width: 155px;
  height: 136px;
  box-shadow: inset 0 -148px 9px -8px #000000a8;
  text-align: right;
  color: white;
  font-size: 12px;
  padding: 8px 0px;
  font-weight: 500;
}
.pagination>.active>a{
  background-color: #ffc107;
  border-color: #ffc107;
}
.pagination>li>a:focus{
  display: none !important;
  /* color: #000 !important;
  background-color: #ffc107 !important;
  border-color: #ffc107 !important; */
}
.pagination>li>a, .pagination>li>span {
  position: relative;
  color: #ffc107;
  font-size: 14px;
  margin: 0px 3px;
}
.recap-seaven-div-one-one-div-rec {
  position: absolute;
  right: 64px;
  bottom: 0px;
  width: 140px;
  height: 258px;
  box-shadow: inset 0 -268px 9px -8px #000000a8;
  text-align: right;
  color: white;
  font-size: 12px;
  padding: 116px 0px;
  font-weight: 500;
}
.video-recap-img img {
  border-radius: 10px;
}
.shop-section {
  padding: 20px;
}
.shop-img {
  width: 250px;
}
.shop-img img {
  width: 100%;
  height: 280px;
  border-radius: 15px;
}
.shop-p p {
  font-size: 17px;
  text-align: center;
  color: white;
}
.shop-i img {
  padding-top: 14px;
  width: 100%;
  /* height: 500px; */
}
label.form-control-1-lable {
  font-size: 14px;
  margin-bottom: 7px;
}
.shop-right-head{
  font-size: 25px;
  color: rgba(89,84,84,0.75);
    font-weight: 500;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}
.shop-right-pra{
  color: red;
  font-size: 15px;
}
.shop-r-pra{
  color: rgba(89,84,84,0.75);
  font-size: 17px;
}
.podcast {
  padding: 20px;
}
.podcast-img img {
  width: 127px !important;
  border-radius: 50%;
  height: 127px;
  margin: 0 auto;
  border: 4px solid #fff;
  position: relative;
}
.podcast-box h6 {
  color: #010101;
  text-align: center;
  font-size: 15px;
  padding-top: 10px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.div-podcast {
  text-align: center !important;
  color: #ffc107;
  font-size: 12px;
    font-weight: 400;

}

.podcast-bottam-div {
  width: 100%;
  display: flex;
  margin-bottom: 18px;
  margin-top: 10px;
}
.podcast-bottam-head {
  width: 19%;
  background-color: #d71a1a;
  color: white;
  padding: 4px 9px;
}
.podcast-bottam-head > h5 {
  text-align: left;
  font-size: 20px;
  padding: 10px 0px;
  margin-bottom: 0px;
}
.podcast-bottam-head-div {
  width: 85%;
  background-color: #504545d1;
  color: white;
  padding: 13px 17px;
}
.podcast-bottam-head-div > h5 {
  font-size: 20px;
}
.pod-img img {
  width: 100%;
  border-radius: 14px 14px 0px 0px;
  height: 100%;
}

.mediy-gallery-div{
  padding: 20px 20px 0px 20px;
}
.watch-section {
  padding-top: 31px;
}

.media-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .media-gallary-heading h3 {
  font-size: 22px;
  background-image: linear-gradient(73deg, #08560875, #0c710514);
  margin: 10px 0px;
  color: #fff;
  padding: 11px 19px 11px 20px;
} */
.media-gallary-heading h3 {
  font-size: 22px;
  /* background-image: linear-gradient(73deg, #08560875, #0c710514); */
  margin: 10px 0px;
  color: #1e1e1e;
  padding: 11px 19px 11px 20px;
  font-weight: 700;
}

.gallery{
  padding: 7px 7px;
  background-color: #fff;
  width: 100%;
}

.img-gallery img{
  width: 100%;
  height: 540px;
  border-radius: 10px;
  object-fit: cover !important;
}
.img-gallery-div img{
  width: 100%;
  height: 260px;
  border-radius: 10px;
}
.img-gallery-div{
  padding: 0px 9px 7px 3px;
 
}
.right-section-listen {
  padding: 20px;
}
.listen-div{
  margin-top: 0px;
  margin-bottom: 8px;
}

.video-lis-btn-div .btn-lis{
  border-radius: 21px;
  padding: 7px 29px;
  background-color: transparent;
  color: white;
  font-size: 11px;
  border: 1px solid white;
  margin-top: 15px;
}
.listen-img-div img {
  /* box-shadow: rgb(238 232 232 / 53%) 3px 0px 28px; */
  width: 100%;
  object-fit: cover;
  height: 333px;
  border-radius: 20px 20px 0px 0px;
}

.listen-icon-div{
 
  /* margin: auto; */
  display: flex;
  /* text-align: center; */
  color: white;
  /* width: 100%; */
  justify-content: center;
}

.listen-icon-div .listion-icon{
  font-size: 11px;
  margin: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 2px solid white;
}
.heading-section-lis{
  background-color: #100f0f;
  padding: 10px ;
  margin-bottom: 20px;
}
.heading-section-lis>h5{
  font-size: 16px;
  color: white;
}

.listion-icon-li{
  margin-top: 5px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid white;
  font-size: 50px !important;
  padding-left: 4px;

}
.icon-lis{
  font-size: 19px !important;
}
section.view-story1 img {
  width: 100%;
  /* padding-top: 10px; */
  height: 500px !important;
  border-radius: 0px !important;
}







.right-section-listen>h6 {
  font-size: 20px;
  width: 100%;
  border-bottom: 1px solid;
  color: white;
  margin: auto;
  padding-bottom: 9px;
}
.left-player-body p {
  font-size: 15px;
  padding-top: 5px;
  margin-bottom: -10px;
}
.right-section-listen>p{
  color: white;
  font-size: 12px;
  padding-top: 8px;
  margin-bottom: 3px;
}
.listen-new-item {
padding-top: 6px;
}
/* 
contact us */

#contact {
  width: 100%;
  height: 100%;
}
p.contact-prgrap {
  font-size: 15px;
  margin-bottom: 23px;
  color: #fff;
  margin-top: -2px;
}
.section-header {
  padding: 40px 0px 0px;
  font-size: 24px;
  color: #fff;
}
.send-button {
  margin-top: 14px;
  height: 44px;
  border-radius: 30px;
  color: #fff;
 border: 1px solid #fff;
  width: 100%;
  background: transparent;
}
.send-text {
  font-size: 14px;
  letter-spacing: 2px;
}
.list-item i {
  font-size: 20px;
  line-height: 40px;
}
.list-item {
  color: #fff;
}

.contact-text h5 {
  font-size: 15px !important;
  padding-left: 37px;
  color: #fff !important;
}
ul.contact-list h6 {
  font-size: 20px;
  color: #cf9d0c;
  width: 413px;
  padding-bottom: 11px;
  border-bottom: 1px solid #fff !important;
}
h1.contact-header-form {
  font-size: 20px;
  color: #cf9d0c;
  padding-bottom: 11px;
}
.contact-text {
 font-size: 15px;
  color: #fff;
}

.place {
  margin-left: 15px;
}

.phone {
  margin-left: 15px;
}

.gmail {
  margin-left: 15px;
}

.contact-text a {
  color: #fff;
  text-decoration: none;
  transition-duration: 0.2s;
}

.contact-text a:hover {
  color: #fff;
  text-decoration: none;
}
.social-media-list {
  position: relative;
}

.form-control::placeholder {
  color: #fff !important;
}

.form-control {
  display: block !important;
  width: 100% !important;
  padding: 23px !important;
  margin-bottom: 0px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  background-color: transparent !important;
  border: 1px solid #fff !important;
  border-radius: 30px !important;
}

.social-media-list li a {
  color: #fff;
  top: -9px;
  left: 8px;
  position: absolute;
  font-size: 13px;
}
.form-horizontal .form-group{
  margin-left: 0px;
  margin-right: 0px;
}
.social-media-list li {
  position: relative;
  display: inline-block;
  height: 30px;
  width: 30px;
  margin: 10px 8px;
  line-height: 43px;
  border-radius: 50%;
  color: #fff;
  background-color: transparent;
  cursor: pointer;
  border: 1px solid #fff;
}
.social-media-list li:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  line-height: 48px;
  border-radius: 50%;
  opacity: 0;
  box-shadow: 0 0 0 1px #5f6a74;
}
.social-media-list li:hover {
  background-color: #fff;
  height: 30px;
  width: 30px;
  border: none;
}
.social-media-list li:hover a {
  color: #000;
}
.interview-img-section.box {
  position: relative;
  width: 100%;
  cursor: pointer;
}
.interview-img-section.box:hover{
  box-shadow: rgb(238 232 232 / 92%) 4px 0px 18px;
}
/******** Shop New  ***************************/
 .single-product img {
  width: 100%;
 }
 .section-products .header {
  text-align: center;
  margin-top: 28px;
  margin-bottom: 28px;
}

.section-products .header h3 {
  font-size: 16px;
  color: #c59810;
  font-weight: 400;
}
.section-products .header h2 {
  font-size: 28px;
  font-weight: 500;
  color: #000;
}

.section-products .single-product {
  margin-bottom: 26px;
  background-color: #fff;
}

.section-products .single-product .part-1 {
  position: relative;
  overflow: hidden;
}
.part-2 {
  padding: 13px 12px;
}
.section-products .single-product .part-1::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all 0.3s;
}

.section-products .single-product:hover .part-1::before {
  transform: scale(1.2,1.2) rotate(5deg);
}
.section-products .single-product .part-1 .discount,
.section-products .single-product .part-1 .new {
  position: absolute;
  top: 11px;
  left: 0px;
  color: #ffffff;
  background-color: #fe302f;
  padding: 2px 8px;
  text-transform: uppercase;
  font-size: 11px;
}

.section-products .single-product .part-1 .new {
  left: 0;
  background-color: #444444;
}

.section-products .single-product .part-1 ul {
  position: absolute;
  bottom: -41px;
  left: 15px;
  opacity: 0;
  transition: bottom 0.5s, opacity 0.5s;
}

.section-products .single-product:hover .part-1 ul {
  bottom: 30px;
  opacity: 1;
}

.section-products .single-product .part-1 ul li {
  font-size: 19px;
}

.section-products .single-product .part-1 ul li a {
  margin-bottom: 8px;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 36px;
  background-color: #283844;
  color: #fff;
  text-align: center;
}

.section-products .single-product .part-2 .product-title {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}

.section-products .single-product .part-2 h4 {
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
  color: #ffc107;
}

.section-products .single-product .part-2 .product-old-price {
  position: relative;
  padding: 0 1px;
  margin-right: 9px;
  opacity: 0.7;
  font-size: 15px;
  font-weight: 400;
  color: #dc3545;
}

.section-products .single-product .part-2 .product-old-price::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #d90015;
  transform: translateY(-50%);
}
.listen-showw {
  margin-bottom: -10px;
  padding-bottom: 29px;
  background: #000;
}
.podcast-bottam .nav-tabs .nav-link:focus{
display: none !important;
}
/* .podcast-bottam .nav-tabs .nav-link:hover{
  display: none !important;
  } */
section.new-store {
  padding: 20px 0px 20px 0px;
  position: relative;
  background-image: url(https://soundchatradio.com/wp-content/uploads/2021/04/sc-footer-bgn2.jpg);
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100% !important;
}
.shop-text h1 {
  text-align: left;
  font-size: 56px;
  font-weight: 600;
  color: #fff;
  padding: 100px 0px 0px 0px;
}
svg.shop-11 {
  margin-top: -3px;
  margin-right: 10px;
}
.store-img img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}
.shop-text button {
  text-align: center;
  float: left;
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  margin: 17px 0px 0px 0px;
  font-size: 16px;
  padding: 10px 44px;
}
.shop-text h4 {
  text-align: left;
  color: #ffc107;
  padding-top: 4px;
  font-size: 28px;
}
.pd-wrap {
	padding: 40px 0;
	font-family: 'Poppins', sans-serif;
}
.heading-section {
	text-align: center;
	margin-bottom: 20px;
}
.sub-heading {
	font-family: 'Poppins', sans-serif;
    font-size: 12px;
    display: block;
    font-weight: 600;
    color: #2e9ca1;
    text-transform: uppercase;
    letter-spacing: 2px;
}
.heading-section h2 {
	font-size: 32px;
    font-weight: 500;
    padding-top: 10px;
    padding-bottom: 15px;
	font-family: 'Poppins', sans-serif;
}

.product-thumb .item img {
	height: 100px;
}
.product-name {
	font-size: 22px;
	font-weight: 500;
	line-height: 22px;
	margin-bottom: 4px;
}
.product-price-discount {
	font-size: 22px;
    font-weight: 400;
    padding: 10px 0;
    clear: both;
}
.product-price-discount span.line-through {
	text-decoration: line-through;
    margin-left: 10px;
    font-size: 17px;
    vertical-align: middle;
    color: #000;
}
.product-price-discount span {
  font-size: 20px;
  font-weight: 600;
  color: #6e5508;
}
.display-flex {
	display: flex;
}
.align-center {
	align-items: center;
}
.product-info {
	width: 100%;
}
.reviews-counter {
    font-size: 13px;
}
.reviews-counter span {
	vertical-align: -2px;
  font-size: 17px;
}
.product-dtl p {
	font-size: 14px;
  padding-bottom: 3px;
	line-height: 24px;
	color: #000;
}
.product-count {
	font-size: 16px;
    margin-top: 10px;
}
.product-dtl {
  padding-top: 41px;
  padding-left: 45px;
}
.product-count .qtyminus,
.product-count .qtyplus {
	width: 34px;
    height: 34px;
    text-align: center;
    font-size: 19px;
    line-height: 31px;
    color: #000;
    border: 1px solid;
    cursor: pointer;
}
.qnt {
  padding: 7px 0px 0px 0px;
  width: 88px;
  text-align: center;
  border: 1px solid;
}
.round-black-btn {
  border-radius: 1px;
  background: #212529;
  color: #fff;
  padding: 7px 45px;
  display: inline-block;
  margin-top: 20px;
  font-size: 15px;
  border: solid 2px #212529;
  transition: all 0.5s ease-in-out 0s;
}
.round-black-btn:hover,
.round-black-btn:focus {
	background: transparent;
	color: #212529;
	text-decoration: none;
}
.review-form .form-group {
	clear: both;
}
.mb-20 {
	margin-bottom: 20px;
}
.rating ul {
  display: flex;
}
.rating ul li {
  margin-right: 7px;
}

.review-heading {
	font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 6px;
    text-transform: uppercase;
    color: #000;
}
.review-form .form-control {
	font-size: 14px;
}
.review-form input.form-control {
	height: 40px;
}
.review-form textarea.form-control {
	resize: none;
}
.review-form .round-black-btn {
	text-transform: uppercase;
	cursor: pointer;
}
.product-variation {
  display: flex;
  align-items: center;
}
.product-label-text {
  font-size: 17px;
  white-space: nowrap;
}
.range-variant {
  display: flex;
  margin: 0 0px 0;
 
}
.range-variant li {
  border: 2px solid #e1e2e9;
  background: #fff;
  border-radius: 51px;
  min-width: 30px;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  margin: 3px;
  cursor: pointer;
  font-size: 10px;
}

.store-description p {
  font-size: 14px;
  line-height: 24px;
  color: #000;
}
.store-description h4 {
  font-size: 23px;
}
.store-tabs {
  margin-top: 37px !important;
}

section.bg {
  background: #000;
  position: relative;
}
.store-tabs .nav-tabs .nav-link.active {
  color: #ffc107;
  font-size: 18px;
  background-color: #000;
  padding: 0.55rem 3rem;
  border-bottom: 2px solid #ffc107;
}
.nav-tabs {
  border: 1px solid #c7bfbf !important;
}
.store-shoppiner {
  background: #e3e3de;
}
.store-tabs .nav-tabs .nav-link{
  color: #000;
  font-size: 18px;
  padding: 0.55rem 3rem;
}
.form-control-1 {
  display: block !important;
  width: 100% !important;
  padding: 10px !important;
  margin-bottom: 14px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  background-color: transparent !important;
  border: 1px solid #919497 !important;
}
form.shop-form-reviews {
  margin-top: 15px !important;
}
.store-description button#submit {
  padding: 10px 30px;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid #919497;
}
section.section-products {
  background: #e3e3de;
}
.section-products .header h4 {
  text-align: left;
  font-size: 24px;
}
  .img-gallery {
    transition: all .3s ease;
    
  }
  .img-gallery:hover{
    transform: scale(1);
    -webkit-filter: brightness(.5);
    filter: brightness(.5);
  }
  .img-gallery-div{
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -o-transition: all .3s ease;
  }
  p.errormessage {
    font-size: 14px;
    color: #f90c0c;
    text-align: left;
}
  .img-gallery-div:hover{
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -webkit-filter: brightness(.5);
    filter: brightness(.5);
  }
  .live-podcast:hover img{
    -webkit-filter: brightness(.5);
    filter: brightness(.5);
  }
  .interview-img-section-55:hover{
    box-shadow: rgb(238 232 232 / 92%) 4px 0px 18px;
  }
  .interview-img-section-55.box {
    /* border-width: 4px;
    border-bottom-style: solid; */
    /* border-image: linear-gradient(to right, rgb(139, 16, 0), rgb(204, 168, 50)) 1; */
    position: relative;
}
.news-arive-1 {
  margin-top: 53px;
  position: relative;
}
.inter-n-veiw {
  padding: 0px 15px;
}
.adv-div img {
  width: 100% !important;
  object-fit: cover;
  height: 417px !important;
  position: relative;
  -webkit-filter: brightness(.5);
  filter: brightness(.5);
}
@media only screen and (min-width: 576px) and (max-width: 991px)  {
  .adv-div img {
 width: 100% !important;
 height: 400px !important;
  }
}
/* .adv-div:hover img {
  
} */
.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.adv-div .image {
  opacity: 0.3;
}

.adv-div .middle {
  opacity: 1;
}

.head-images-news {
  position: absolute;
  top: 0px;
  position: absolute;
  top: 39%;
  left: 4%;
  /* transform: translate(-50%, -50%); */
}
.head-images-news h2 {
  color: #fff;
  font-size: 20px;
}

.middle a {
  background: #ffc107;
  padding: 10px 13px;
  color: #000;
  font-size: 15px;
}

.react-player-setc{
  padding: 6px 6px;
}

/* .chat-user.watch-user {
  height: 494px;
} */

/*************** LatesRecaoTvshowvideo **************/
.reacp-contnent.new-1 {
  /* height: 52px; */
  width: 100%;
}
.LatesReacp-view h5 {
  font-size: 19px;
  color: #ffc107;
  font-weight: 300;
  text-align: center;
  margin-bottom: 20px;
}
span.new-share {
  margin-right: 25px;
}
.reacp-tv-text {
  width: 100%;
  color: #ffffffb5;
  padding: 20px 12px 0px;
}
.reacp-contnent.new-1 p {
  font-size: 13px;
}
.reacp-tv-text h3 {
  font-size: 25px;
  padding: 0px 5px 5px;
  font-weight: 400;
  color: #fff;
}
.reacp-tv-text p {
  color: #fff;
  font-size: 14px;
  padding-top: 10px;
}
.share-text i {
  font-size: 16px;
  color: #fff;
  margin-right: 7px;
}
.share-text span {
  font-size: 14px;
  color: #fff;
}
.share-text {
  width: 263px;
  cursor: pointer;
  padding: 8px 10px;
  background: #541117;
  /* font-size: 10px;  */
}

a.header-menu-2.active {
  padding-right: 8px;
  color: #fff;
  font-size: 21px;
  display: none;
}
.navbar {
  background: #000;
}
nav.mobile.navbar.navbar-expand-lg.navbar-light {
  display: none;
}
nav.desktop.navbar.navbar-expand-lg.navbar-light {
  display: block;
}
@media(max-width:1440px){
  /* .chat-user {
    height: 462px;
    background-image: linear-gradient(180deg, #3e030e, #321a2ee6);
    border-radius: 5px;
    text-align: center;
    padding-top: 130px;
} */


.video-soundchat img {
  width: 100%;
  height: 490px;
}
/* .day-div {
  left: 172px !important;
  
}       */
}

@media(max-width:991px){

.user-profile-new {
  margin-left: 10px;
}


.section-div-right-1 {
  padding-top: 4px;
  color: #ffc107;
  padding-right: 10px;
  font-size: 16px;
  display: block;
}

.hesder-good-day {
  display: flex;
  float: right;
  position: absolute;
  top: 9px;
  right: 15px;
}
.header-menu-2 {
  color: #fff;
  font-size: 20px;
  margin-left: 0px;
  display: none;
}
h1.header-coutong {
  top: 0px;
  right: -5px;
}


  .navbar-toggler {
    background-color: #f5f5f5 !important;
    border-radius: 50px !important;
    height: 30px;
    width: 30px;
}
.navbar-collapse.collapse {
 display: block !important; 
 
}
.collapse:not(.show) {
  display: none !important;
}
a.header-humger {
  display: none;
}
.navbar-toggler-icon {
  margin-left: -2px;
}
a.header-menu {
  color: #000 !important;
}
a.header-menu-2{
  display: block !important;
}
a.header-menu-1{
  display: none;
}
.navbar>.container-fluid, .navbar>.container-lg, .navbar>.container-md, .navbar>.container-sm, .navbar>.container-xl, .navbar>.container-xxl {
  justify-content: left !important;
}
.navbar-nav{
  background: #fff !important;
  width: 100%;
  /* border-bottom: 1px solid; */
}

a.header-menu {
  border-bottom: 1px solid #e7dcdc;
  padding: 11px 10px;
}
nav.mobile.navbar.navbar-expand-lg.navbar-light {
  display: block;
}
nav.desktop.navbar.navbar-expand-lg.navbar-light {
  display: none;
}
}
@media(max-width:440px){
  .div-h6 {
    font-size: 12px;
}
}
/* @media(max-width:1063px){

.header-section-tow {
  width: 35%;
}
} */
/* @media (max-width: 989px){
.header-section-tow {
    width: 39%;
}}
@media (max-width: 921px){
.header-section-one {
    width: 63%;
}} */
/* @media (max-width: 846px){
.header-section-tow {
    width: 46%;
}}
@media (max-width: 766px){
.header-section-tow {
    width: 56%;
}}
@media (max-width: 687px){
.header-section-tow {
    width: 67%;
}
} */
/* @media (max-width: 686px){
  .header-section-one {
      width: 100%;
  }
  .header-section-tow {
    width: 100%;
  }
  } */
  /* @media(max-width:475px){
    .logo-soundchat img {
      width: 68px;
      height: 68px;;
  }
  } */
  @media (max-width: 390px){
/* .header-section-tow {
    width: 100%;
    margin-left: -58px;
} */
.logo-text-heading {
  padding-left: 0px;
}

}
/*****************Login********************/

.register {
  position: fixed;
  background-image: url(https://soundchatradio.com/wp-content/uploads/2021/04/sc-footer-bgn2.jpg);
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100% !important;
  height: 100%;
  padding: 84px 0px 50px 0px;
  background-attachment: fixed;
}
span.company__logo img {
  width: 100%;
}
.main-content{
  height: 500px;
	width: 75%;
  margin: auto !important;
}
lable.login-lable {
  float: left;
  color: #fff;
  font-size: 14px;
  text-align: left;
}
.login-demo {
  position: relative;
}
span.login-user-demo {
  position: absolute;
  margin-top: 11px;
  left: 9px;
  line-height: 35px;
  font-size: 16px;
  border-radius: 50%;
  background-color: #fff;
  width: 45px;
  height: 45px;
  color: #813a41;
}
.login-text h2 {
  font-size: 27px;
  text-align: left;
  border-bottom: 1px solid;
  padding-bottom: 11px;
  color: #fff;
  padding-top: 0px;
}
.login-from-btn{
  width: 100%;
  border-radius: 50px;
  border: 2px solid #fff;
  padding: 11px;
  padding-left: 2em;
  outline: none;
  font-size: 15px;
  color: #fff;
  margin-top: 9px !important;
  background: transparent;
}
.forgot a {
  font-size: 13px;
  color: #fff;
  float: right;
  font-weight: 300;
  padding-top: 5px;
}
.forgot a:hover {
  color: rgb(228, 207, 20);
}
.rember label {
  font-size: 13px;
  color: #fff;
  margin-bottom: 0px;
  font-weight: 300;
  padding-left: 9px;
}
.rember {
  float: inherit;
}
p.errormessage-1 {
  font-size: 12px;
  color: red;
  margin-bottom: 10px;
  margin-top: -4px;
}
.form__input {
  width: 100%;
  position: relative;
  font-size: 14px;
  padding: 11px 4px;
  border-radius: 50px;
  border: 2px solid #fff;
  padding-left: 2em;
  outline: none;
  margin:10px 0px;
  padding-left: 55px;
  color: #fff;
  background: transparent;
}

span.password-1 {
  position: absolute;
  color: #fff;
  font-size: 16px;
  top: 43px;
  right: 27px;
}
.dont-here p {
  font-size: 14px;
  color: #fff;
  font-weight: 300;
  text-align: center;
}
.dont-here-1 {
  color: #ffc107;
  padding-left: 10px;
  font-weight: 500;
  font-size: 15px;
}

/*************Cart**************/
.cart-main {
  background: #F9F5F0;
  padding: 50px 0px;
}
.countinew-shpnig i {
  margin-right: 5px;
}
.checkout.block {
  border: 1px solid;
  color: #000;
  text-align: center;
}
table {
  width: 100%;
}
td.text-right.total-new-tt {
  font-size: 14px;
}
.grand-tut-t1 {
  background: #f3f5f3;
  width: 100%;
  padding: 11px;
  margin: 16px 0px 10px 0px;
  color: #000;
}
.checkout .block {
  width: 100%;
  border: none;
  margin: 10px 0px 0px 0px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  background: #000;
  padding: 14px;
}
.total-shping-cart h5 {
  font-size: 14px;
  padding-bottom: 16px;
  border-bottom: 1px solid #cdc8c8;
  font-weight: 600;
}
.countinew-empty.county {
  float: right;
}
.countinew-empty.ceconu {
  float: left;
}
.countinew-shpnig .countinew-empty {
  color: #000;
  border: 1px solid #ddd;
  padding: 10px 34px;
  font-size: 14px;
}
.checkout {
  width: 100% !important;
  display: flex;
}
.grand-tut-t1 h6 {
  font-size: 14px;
}
.grand-tut-t1 span {
  float: right;
}
.img-sm{
    width: 80px;
    height: 80px
}
.itemside .info p {
  font-size: 12px;
}

.itemside .info a.title.text-dark {
  color: #000;
  font-weight: 500;
  font-size: 17px;
}
.itemside .info{
    padding-left: 15px;
}

.table-shopping-cart .price{
    font-size: 15px;
}

th.cart-delete-1 {
  cursor: pointer;
  font-size: 16px !important;
}
.table-shopping-cart .price-wrap {
  margin-top: 25px;
}
input.cart {
  width: 64px;
  height: 36px;
  border: 1px solid #dce3e9;
  margin-top: 20px;
}
.text-muted{
    color: #969696 !important
}
td.cart-delete {
  font-size: 17px;
}
.card{
    border: none !important;
}
.table>thead>tr>th {
  vertical-align: bottom;
  color: #000;
  font-size: 14px;
  padding: 16px 12px !important;
  border-bottom: none !important;
}
.itemside{
    position: relative;
    display: flex;
    width: 100%
}
.dlist-align{
    display: flex
}
[class*="dlist-"]{
    margin-bottom: 5px
}
.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
  padding: 7px 13px;
}
td.cart-delete i {
  position: absolute;
  margin-top: 24px !important;
  color: #cf1313;
}
.total-shping-cart {
  width: 100%;
  padding: 20px 10px 20px 20px;
}
td.cart-total-text {
  font-size: 15px;
  padding: 19px 0px 0px 0px;
}
.price-subtile {
  margin-top: 25px;
  font-size: 15px;
}
/*****************checkout************/
.checkout-bg-default {
  background: #fff;
  padding: 23px;
}
.Checout-main {
  background: #F9F5F0;
  padding: 30px 0px;
}
.checkbox.bg.pull-right span {
  font-size: 14px;
}
.checkbox.bg.pull-right {
  padding-top: 13px;
}
input.form__input-1 {
  width: 100%;
  font-size: 13px;
  border: 1px solid #d5cdcd;
  padding: 13px;
  margin-bottom: 10px;
  margin-top: 9px;
  border-radius: 5px;
}
.checkout-bg-default h4.my-4 {
  font-weight: 600;
  border-bottom: 1px solid #ebdbdb;
  padding-bottom: 12px;
}
lable.billing-checkout {
  font-size: 15px;
}
h6.Accepted-card {
  font-size: 14px;
  font-weight: 300;
  color: #e9172b;
  padding: 12px 0px;
}
.form-group-neety {
  margin-right: 7px;
}
.Checout-main {
  padding: 30px 0px;
}
.checkout-bg-default h5.my-4 {
  font-weight: 600;
  font-size: 15px;
  padding-bottom: 12px;
}
.countinew-empty.ceconu-1 {
  float: right;
}
.main-header-checkout {
  border-bottom: 1px solid #eee;
}
td.main-header-card-tcet {
  font-size: 14px;
  font-weight: 600;
  padding: 13px 0px;
}
.card-cat-checkout-man {
  width: 380px;
  position: absolute;
  right: 36px;
  top: 41px;
  background: #fff;
}
a.header-menu-1:hover{
  color: white;
}
.dont-here-1:hover{
  color: #fff;
}
.login-text p {
  font-size: 15px;
  color: #fff;
  margin-top: 0px;
  border-bottom: 1px solid;
  padding-bottom: 9px;
}
.login-text h3 {
  font-size: 24px;
  color: #fff;
}
span.password {
  position: absolute;
  font-size: 17px;
  color: #fff;
  top: 20px;
  right: 12px;
}
.form__input_register {
  position: relative;
  color: #fff;
  width: 100%;
  border-radius: 50px;
  border: 2px solid #fff;
  padding: 14px;
  padding-left: 16px;
  outline: none;
  margin: 6px 0px;
  font-size: 14px;
  background: transparent;
}
.media-content-recap-0-section {
  position: relative;
}

@media(max-width:767px){
  .news-video-section .react-player__preview {
    width: 100%;
    position: relative;
    height: 500px !important;
  }
}

.media-content {
  position: relative;
  width: 200px;
  cursor: pointer;
}

.media-content img.image {
  position: relative;
  width: 200px;
  object-fit: cover;
  height: 151px;
  border-radius: 10px 10px 0px 0px;
}
.media-content.image {
  display: block;
  width: 100%;
  height: auto;
}
.media-content-text {
  text-align: left;
  padding: 6px;
  font-size: 13px;
  line-height: 17px;
  color: #fff;
}
.media-content-text p{
  font-size: 13px;
  color: #fff;
}
.overlay-div {
  z-index: 99;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #0000009c;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease;
}

.media-content:hover .overlay-div {
  height: 100%;
}

@media (max-width: 991px){
  .news-arive-1 {
    margin-top: 15px;
}
  .content {
    height: 159px !important;
    box-shadow: inset 0 -59px 9px 8px #000000c2 !important;
    padding-top: 112px !important;
}

  .shop-text h1 {
    padding: 28px 0px 0px 0px;
}
.product-variation {
  display: block;
}
.main-content {
  height: 100%;
  width: 100%;
  margin: auto !important;
}

.cart-main {
  padding: 20px 0px;
}
.row.mt-4.nee {
  margin-bottom: 16px;
}
h2.cart-text.mb-5 {
  margin-bottom: 14px !important;
}
  }

  @media (max-width: 822px){
  .content {
      height: 159px !important;
      box-shadow: inset 0 -59px 9px 8px #000000c2 !important;
      padding-top: 100px !important;
  }

  }
 
@media(max-width:767px){
  button.navbar-toggler.collapsed {
    border-radius: 50px !important;
    width: 30px !important;
    height: 30px !important;
}

  .media-content {
    width: 100%
  }


.reacp-contnent .recap-pra {
  font-size: 12px;
}
  .checoutng-1 {
    margin-top: 13px;
}
/* .video-slider img {
  width: 100%;
  height: 400px;
  margin: auto;
  object-fit: cover;
  background-position: center;
} */
.checkbox.bg.pull-right {
  float: left !important;
  padding-top: 0px;
  padding-bottom: 10px;
}
  input.form__input-1 {
    margin-bottom: 6px;
    margin-top: 5px;
}
lable.billing-checkout {
  font-size: 13px;
}
.checkout-bg-default h5.my-4 {
  padding-bottom: 0px;
}
  td.cart-delete i {
    position: relative;
    top: 24px;
}
  span.company__logo.register img{
    margin-top: 0px;
  }
span.company__logo img {
  height: 209px;
  width: 100%;
  object-fit: contain;
}
.rember {
  float: left;
}
  .shop-text h1 {
    font-size: 39px;
    padding: 14px 0px 0px 0px;
}
.shop-text h4 {
  padding-top: 0px;
  font-size: 22px;
}
.shop-text button {
  margin: 9px 0px 12px 0px;
  font-size: 14px;
  padding: 6px 22px;
}
svg.shop-11 {
  margin-right: 0px;
}
.store-img img {
  height: 100%;
  /* object-fit: cover; */
}
.shop-i img {
  height: 100%;
}
.product-dtl {
  padding-top: 10px;
  padding-left: 0px;
}
.product-variation {
  display: flex;
}
.store-tabs {
  margin-top: 20px !important;
}
.store-tabs .nav-tabs .nav-link.active {
  font-size: 13px;
  padding: 10px;
}
.store-tabs .nav-tabs .nav-link {
  font-size: 13px;
  padding: 10px;
}
}

@media (max-width: 767px){
.navbar-toggler {
    background-color: #f5f5f5 !important;
    border-radius: 0px !important;
    height: auto !important;
    width: auto !important;
}
.navbar-collapse.collapse {
  width: 100%;
  margin: 0px 0px;
}
}
@media (max-width: 403px){
.content {
    padding-top: 97px !important;
}}
@media(max-width:520px){
  .video-slider img {
    height: 303px;
}
}

@media (max-width: 473px){
.video-slider img {
    height: 354px;
}}
@media only screen and (max-width:775px) and (min-width:365px){
  .recap-video-heading .recap-video-h1 {
    font-size: 30px !important;
} 
}
@media(max-width:364px){
  .recap-video-heading .recap-video-h1 {
    font-size: 20px;
}
.recap-video-heading .recap-video-pra {
  font-size: 13px;
}
}
@media only screen and (max-width: 575px) and (min-width: 320px)
{
  .pagination>li>a, .pagination>li>span {
    margin: 0px 1px;
}
.recap-video-heading {
  padding: 9px 36px 0px 19px;
  width: 100%;
  box-shadow: inset 479px 4px 82px -66px; 
}
.recap-video-heading.recap-d1 {
  width: 100%;
  position: absolute;
  box-shadow: inset 436px 4px 82px -26px #000000c2 !important;
}
.recap-icon-w-div {
  left: 33px;
  font-size: 11px;
  height: 15px;
  width: 15px;

}
.video-recap-btn-div button {
  padding: 6px 26px;
  font-size: 11px;
}
.current-trn-section h5 {
  font-size: 19px;
  padding-top: 8px;
  text-align: left;
  padding-left: 17px;
}
}
@media(max-width:425px){

  .img-recap img {
    height: 200px;
}
.news-heading h5 {
  font-size: 14px;
}
.news-heading h6 {
  font-size: 14px;
}
}
@media(max-width:355px){
  .video-slider.recap-dd img {
    height: 282px;
}
}
@media(max-width:663px){
  .podcast-bottam>.nav-tabs .nav-item {
    padding: 0px;
    padding-top: 0px;
    font-size: 13px;
}
}
@media(max-width:449px){
  .countinew-shpnig .countinew-empty {
    padding: 8px 13px;
    font-size: 12px;
  }
  td.cart-total-text {
    font-size: 14px;
    padding: 10px 0px 0px 0px;
}
.total-shping-cart {
  padding: 14px;
}

.grand-tut-t1 {
  margin: 8px 0px 4px 0px;
}
}
@media(max-width:425px){
  .podcast-bottam>.nav-tabs {
    color: #fff;
    border: none !important;
}
.store-description h4 {
  font-size: 19px;
}
.podcast-bottam>.nav-tabs .nav-item {
  font-size: 13px;
  padding: 7px;
}
  .product-name {
    font-size: 19px;
    margin-bottom: 0px;
}
.product-price-discount span {
  font-size: 16px;
}
.section-products .header h4 {
  font-size: 20px;
}

.shop-text h1 {
  font-size: 28px;
  padding: 0px 0px 0px 0px;
}
.shop-text h4 {
  padding-top: 0px;
  font-size: 18px;
}
.product-price-discount {
  padding: 0px 0;
}
.title {
  font-size: 15px;
  color: white;
  font-family: "Poppins", sans-serif !important;
}
.links-list li {
  margin: 9px 0px;
}

.sc-div img {
  height: 100%;
  width: 100%;
}
}
@media(max-width:329px){
.store-tabs .nav-tabs .nav-link {
    padding: 7px;
}
.store-tabs .nav-tabs .nav-link.active {
  padding: 7px;
}
}
@media(max-width:575px){
  .react-player {
    width: 100%;
    height: 290px !important;
}
}
@media (max-width: 375px){
.react-player {
    width: 100%;
    height: 166px !important;
}}

@media(max-width:767px){
  .showcase h2 {
    margin-top: 165px !important;
}
.middle {
  top: 38% !important;
}
}
@media (max-width: 767px){
.middle {
    top: 42% !important;
    left: 74px !important;
}

}
@media (max-width: 390px){
.middle {
    top: 47% !important;
    left: 67px !important;
}}
@media (max-width: 327px){
.middle {
    top: 58% !important;
    left: 147px !important;
}
}

@media (max-width: 752px){
.showcase h2 {
    margin-top: 153px !important;
}}
@media only screen and (max-width: 767px) and (min-width: 320px) {

  .adv-div img {
    width: 100%;
    height: 100%;
}
 
    /* .chat-user {
      height: 382px;
      padding-top: 84px;
      margin-top: 10px;
} */
.login-text h1 {
  padding-bottom: 26px;
  padding-top: 17px;
}
}
@media only screen and (max-width: 491px) and (min-width: 320px){
.login-text h1 {
  font-size: 20px;
}

}
@media (max-width: 575px){

.itemside .info {
  padding-left: 6px;
}
.itemside .info a.title.text-dark {
  font-size: 15px;
}

}
@media (max-width: 391px){

.div-h6 {
  font-size: 11px;
}
}
@media only screen and (max-width: 1024px) and (min-width: 320px) {
  .social-media-list li a {
    top: -8px;
}
.mediy-gallery-div{
  padding: 10px 10px 0px 10px;
}
ul.contact-list h6 {
  width:100%;
  font-size: 17px;
  padding-bottom: 7px;
}
.section-header {
  padding: 20px 0px 0px;
  font-size: 22px;
}
p.contact-prgrap {
  font-size: 14px;
  margin-bottom: 13px;

}
.contact-text {
  font-size: 14px;
}
.phone {
  margin-left: 10px;
}

.gmail {
  margin-left: 10px;
}
.place {
  margin-left: 10px;
}
li.list-item {
  margin-bottom: -10px;
}
h1.contact-header-form {
  font-size: 17px;
}
.section-products .header h2 {
  font-size: 22px;
}
.section-products .header {
  margin-top: 20px;
  margin-bottom: 20px;
}


.podcast-bo-box > p {
  font-size: 12px !important;
  margin-bottom: 6px !important;
}
.podcast-vo-div {
  margin: 6px 0px 0px 0px;
}
.podcast {
  padding: 10px;
}
.heading-h4-div h4 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 15px;
}
.heading h4 {
  font-size: 16px;
  padding: 10px 0px;
}
.podcast-heading h4 {
  font-size: 16px;
  padding: 8px 0px;
}


}


.sound-text p {
  font-size: 14px;
  color: #fff;
  padding: 13px;
}

.showcase {
  width: 100%;
  height: 217px;
  position: relative;
  color: white;
  text-align: center;
}
.showcase img {
  border-radius: 25px 25px 0px 0px;
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  top: 0;
  left: 0;
  /* z-index: 99; */
}
.showcase .overlay-div {
  width: 100%;
  height: 226px !important;
  background-color: transparent;
  /* position: absolute; */
  top: 0;
  left: 0;
  box-shadow: inset 0 -81px 15px 3px #000000c2 !important;
  /* z-index: 999; */
}
.showcase h2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  /* display: -webkit-box; */
  margin-top: 155px;
  font-size: 13px;
  margin-bottom: 0px;
  font-weight: 400;
  line-height: 20px;
  /* -webkit-line-clamp: 2; */
  overflow: hidden;
}

.showcase p {
  margin-top: 0px;
  font-size: 14px;
  font-weight: 600;
  color: #ffc107;
}
.day-div {
  position: absolute;
  top: 0px;
  /* height: 31px; */
  right: 0px;
  background: rgb(0 0 0 / 72%);
  color: white;
  z-index: 9;
  border-radius: 20px 0px 0px 20px;
}

.showcase .react-player__play-icon {
  position: relative;
  z-index: 999;
  top: -43px;
}
i.news-it-icon {
  color: #fff;
  position: relative;
  /* font-size: 16px; */
}

/* .new-user-chat-010 {
  margin-top: 37px;
} */
@media(max-width:991px){
  .showcase h2 {
    margin-top: 148px ;
    font-size: 13px;
}
.showcase .overlay-div {
  height: 265px;
}
.middle {
  top: 36%;
  left: 83px;
}
}
@media(max-width:395px){
  .showcase p {
    margin-top: 0px;
}

}

.lates-news-12.react-player__preview {
  border-radius: 25px 25px 0px 0px;
}
ul {
  margin: 0px;
  padding: 0px;
}
.footer-section {
position: relative;
background-image: url("https://soundchatradio.com/wp-content/uploads/2021/04/sc-footer-bgn2.jpg");
background-position: center !important;
background-repeat: no-repeat !important;
background-size: cover !important;
width: 100% !important;
}

.single-cta i {
color: #ffc107;
font-size: 30px;
float: left;
margin-top: 8px;
}
.cta-text {
padding-left: 15px;
display: inline-block;
}
.cta-text h4 {
color: #fff;
font-size: 20px;
font-weight: 600;
margin-bottom: 2px;
}
.cta-text span {
color: #757575;
font-size: 15px;
}
.footer-logo img {
width: 300px;
}

@media only screen and (min-width: 250px) and (max-width: 575px) {
  .footer-logo img {
      width: 320px !important;
      margin: 0px auto !important;
      display: block;
  }
}

.footer-text p {
margin-bottom: 14px;
font-size: 14px;
    color: #7e7e7e;
line-height: 28px;
}
.footer-social-icon span {
color: #fff;
font-size: 16px;

}
.footer-social-icon a {
color: #fff !important;
font-size: 16px;
display: flex;
}
.footer-social-icon i {
  height: 35px;
  margin-bottom: 6px;
  width: 35px;
  border: 1px solid;
  margin-right: 20px;
  text-align: center;
  line-height: 31px;
  /* border-radius: 50%; */
}
.footer-social-icon i:hover {
color: #ffc107;
}

.footer-widget-heading h3 {
color: #fff;
font-size: 17px;
font-weight: 600;
margin-bottom: 40px;
position: relative;
}
.footer-widget-heading h3::before {
content: "";
position: absolute;
left: 0;
bottom: -15px;
height: 2px;
width: 50px;
background: #ffc107;
}
.footer-widget ul li {
display: inline-block;
float: left;
width: 45%;
margin-bottom: 12px;
font-size: 16px;
color: #fff;
}
.footer-widget ul li a:hover{
color: #ffc107;
}
.footer-widget ul li a {
  color: #fff;
  font-size: 16px;
text-transform: capitalize;
}

.copyright-area {
  background: #1c1a1a;
  padding: 15px 0 10px 0px;
}
.copyright-text p {
margin: 0;
font-size: 14px;
color: #fff;
}
.footer-new-head {
  display: flex;
  position: relative;
  top: -9px;
}
.copyright-text p a{
color: #ffc107;
}
.footer-content{
  padding-top: 59px !important;
}
.footer-social-icon {
  display: grid;
  cursor: pointer;
}


@media(max-width:991px){
  .footer-logo img {
      width: 100%;

  }
  .foot-new-t {
      padding-top: 55px;
  }
  .footer-content {
      padding-top: 0px !important;
  }
}
@media(max-width:688px){
  .button-div {
      width: 121px;
  }

.foot-new-t {
  padding-top: 32px;
}
}
@media(max-width:575px){
  .reacp-tv-text h3 {
    font-size: 16px;
}
.sound-text p {
  font-size: 13px;
}
.LatesReacp-view h5 {
  font-size: 17px;
  margin-bottom: 12px;
}
  .foot-new-t {
      padding-top: 0px;
  }
  .button-div {
      width: 160px !important;
      margin: 40px 0px 0px 9px !important;
  }
  .footer-widget ul li {
      width: 34%;
  }
}
@media(max-width:425px){
  .footer-widget-heading h3 {
      font-size: 16px;
      margin-bottom: 28px;
  }
  .footer-widget ul li a {
      color: #fff;
      font-size: 14px;
      text-transform: capitalize;
  }
  .footer-social-icon span {
      color: #fff;
      font-size: 14px;
  }
  .footer-social-icon i {
      height: 30px;
      width: 30px;
      line-height: 28px;
  }
}
@media (max-width: 375px){
.footer-widget ul li {
  width: 39%;
}
.button-div {
  width: 120px;
  margin: 49px 0px 0px 9px;
}
.copyright-text p {
  font-size: 13px;
}

}

@media(max-width:390px){
  .podcast-bo-div {
    height: 250px;
}
}
@media(max-width:375px){
  .podcast-bo-div {
    height: 245px;
}
}
@media (max-width: 320px){
.podcast-bo-div {
    height: 216px;
}
.right-section-listen>h6 {
  font-size: 16px;
}
}


@media(max-width:767px){
  .navbar-collapse {
    padding-right: 3px;
    padding-left: 33px;
}
}
.forgot-paasword {
  position: fixed;
  background-image: url(https://soundchatradio.com/wp-content/uploads/2021/04/sc-footer-bgn2.jpg);
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100% !important;
  height: 100%;
  /* height: 598px !important; */
  padding: 50px 0px 50px 0px;
  background-attachment: fixed;
}

p.forgot-text {
  text-align: left;
  font-size: 14px;
    padding: 5px 0px 12px 0px !important;

}

.company__logo_forgot img {
  width: 255px;
  margin-bottom: -18px;
}
@media(max-width:991px){
  .offset-2 {
    margin-left: 0 !important;
  }
}


/* //// */
 .rhap_time {
  display: none !important;
}
.rhap_container {
  background-color: transparent !important;
}
 .rhap_additional-controls {
  display: none !important;
}

.rhap_progress-section {
  display: none !important;
}
.rhap_container {
  box-shadow: none !important;
  padding: 0px 0px !important;
}

.rhap_stacked .rhap_controls-section {
  margin-top: 0px !important;
}
h6.header-new-bottom-new {
  font-size: 14px;
  padding: 7px 0px 0px 10px;
  text-align: left;
  margin-bottom: 0px;
  color: #ffc107;
}
.rhap_main-controls-button {
  color: #ffc107 !important;
}
.box-container {
  position: relative;
  justify-content: space-between;
  height: 26px;
  --boxSize: 2px;
  --gutter: 11px;
  width: calc((var(--boxSize) + var(--gutter)) * 11);
  display: flex;
  top: -29px;
  left: 46px;
}
.box {
  height: 100%;
  width: var(--boxSize);
  background: linear-gradient(40deg,#364562 20%, #ffc107 100%) no-repeat;
  border-radius: 18px;
}

.box.active {
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.box1 {
  animation-name: quiet;
}
.box2 {
  animation-name: normal;
}
.box3 {
  animation-name: quiet;
}
.box4 {
  animation-name: loud;
}
.box5 {
  animation-name: quiet;
}
.box6 {
  animation-name: normal;
}
.box7 {
  animation-name: quiet;
}

@keyframes quiet {
    25%{
        transform: scale(.6)
    }
    50%{
        transform: scale(.4)
    }
    75%{
        transform: scale(.8)
    }
}

@keyframes normal {
    25%{
        transform: scale(1)
    }
    50%{
        transform: scale(.4)
    }
    75%{
        transform: scale(.6)
    }
}

@keyframes loud {
    25%{
        transform: scale(1)
    }
    50%{
        transform: scale(.4)
    }
    75%{
        transform: scale(1.2)
    }
}
@media(max-width:1240px){
  .box-container {
    --gutter: 8px;
}
}
@media (max-width: 557px){
.box-container {
    --gutter: 5px;
}}
@media(max-width:991px){
  .fade-in-new img{
    height: 100%;
    object-fit: contain;
    margin-bottom: 10px;
  }
}

@media(max-width:1030px){
  .head-images-news {
    text-align: center;
    position: absolute;
    top: 29%;
}
}
/* chatting */
/*====> Chat CSS <=====*/
.chatting-new-window {
  position: fixed;
  background-image: url(https://soundchatradio.com/wp-content/uploads/2021/04/sc-footer-bgn2.jpg);
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100% !important;
}
/* .msger {
  width: 100%;
  position: relative;
} */
/* .msger-header {
  display: flex;
  font-size: 15px;
  justify-content: space-between;
  padding: 7px;
  border-bottom: var(--border);
  background: #eee;
  color: #666;
} */
/* .msger-chat {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}
.msger-chat::-webkit-scrollbar {
  width: 6px;
}
.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}
.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
} */
/* .msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}
.msg:last-of-type {
  margin: 0;
} */

/* .msg-bubble {
  max-width: 450px;
  padding: 15px;
  border-radius: 15px;
} */
/* span.emoji {
  font-size: 15px;
  padding-top: 5px;
  color: #ffc413;
  padding-right: 10px;
} */
/* .btn--send {
  display: flex !important;
  border-radius: 3px;
  position: absolute;
  right: 0px;
} */
/* .msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
  font-size: 14px;
} */
/* .msg-text {
  font-size: 12px;
} */
/* .msg-info-name {
  margin-right: 10px;
  font-weight: bold;
} */
/* .msg-info-time {
  font-size: 0.85em;
  text-align: right;
  color: #ffffffa6;
} */
/* .left-msg .msg-bubble {
  border-bottom-left-radius: 0;
} */

/* .right-msg {
  flex-direction: row-reverse;
}
.right-msg .msg-bubble {
  background: #a8aeb1;
  color: #000;
  border-bottom-right-radius: 0;
} */
/* h5.send-bn {
  color: #fff;
  padding-top: 4px;
  padding-right: 11px;
  font-size: 18px;
} */
/* .msg-chatt-footer {
  display: flex;
  margin-top: 14px;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 4px;
  background-color: transparent;
} */
/* .msg-chatt-text {
  width: 100%;
  padding: 13px;
  font-size: 11px;
  color: #fff !important;
  background-color: #080808;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
} */
/* .btn--send {
  border-radius: 3px;
} */
/* .msger-chat {
    height: 317px;
    width: 100%;
    background: transparent;
} */
/* //////////////////// */
.Toastify {
  font-size: 15px !important;
}
.top-head-section {
  display: flex;
  padding: 0px 0px 4px 10px;
}
p.todu {
  color: #d9edf7;
  margin-left: 41px;
}

/* //////// */
.top-header {
  position: relative;
  background-color: #484545;
}
.form__input_register::placeholder {
  color: #fff !important;
}

.logo_register img {
  width: 100%;
}
.header-section-tow {
  position: absolute;
  width: 32%;
  background-color: #3d3939;
  top: 0px;
  z-index: 99;
  right: 0px;
}
.login {
  position: fixed;
  background-image: url(https://soundchatradio.com/wp-content/uploads/2021/04/sc-footer-bgn2.jpg);
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100% !important;
  height: 100%;
  /* height: 598px !important; */
  padding: 120px 0px 50px 0px;
  background-attachment: fixed;

}

.login-text {
  padding-top: 17px;
}
.login-logo img {
  width: 100%;
  margin-top: -10px;
}
a.bac-to-home {
  font-size: 14px;
  float: left;
  color: #fff;
}
.dont-here {
  float: right;
}
p.contact-rdused {
  padding: 0px 0px 0px 35px !important;
  margin-bottom: 0px;
  margin-top: -9px;

}
@media(max-width:996px){
  a.header-menu {
    padding: 5px 8px;
}
}
@media(max-width:1024px){
  .list-item i {
    line-height: 47px;
}
  p.contact-rdused {
    padding: 0px 0px 10px 31px !important;
  }
}
@media(max-width:767px){
  .social-media-list {
    margin-bottom: 0px;
}
  .forgot-paasword {
    padding: 16px 0px 10px 0px;
}
  .register {
    position: relative;
    padding: 30px 0px 11px 0px;
   
}
.logo_register img {
  width: 200px;
  margin: auto;
  display: block;
}
  .login {
    position: relative;
    padding: 30px 0px 10px 0px;
}
.login-logo img {
  display: none !important;
}
.login-text {
  padding-top: 0px;
  margin-bottom: 15px;
}
.login-text h2 {
  font-size: 20px;
  padding-bottom: 8px;
}
}
@media(max-width:375px){
lable.login-lable {
  font-size: 13px;
}
  .login-text h3 {
    padding-top: 15px;
    font-size: 20px;
    color: #fff;
}
.dont-here p {
  font-size: 13px;
}
.dont-here-1 {
  font-size: 13px;
}
a.bac-to-home {
  font-size: 13px;
}
.forgot a {
  padding-top: 3px;
}
.login-text p {
  font-size: 13px;
  padding-bottom: 5px;
}
.form__input_register {
  padding: 11px;
  font-size: 13px;
}
span.password {
  top: 16px;
}
.login-text h2 {
  font-size: 18px;
  padding-bottom: 8px;
  padding-top: 10px;
}
}

@media(max-width:357px){
.dont-here-1 {
    padding-left: 1px;
    font-size: 12px;
}
.dont-here p {
  font-size: 12px;
}
}



/* //////// */
/* Scrollbar Styling */
.scroll-box {
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  padding-right: 20px;
}

.scroll-box::-webkit-scrollbar {
  width: .4em; 
}
.scroll-box::-webkit-scrollbar,
.scroll-box::-webkit-scrollbar-thumb {
  overflow:visible;
  border-radius: 4px;
}
.scroll-box::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,.2); 
}
.audio-bar {
  position: fixed;
  top: 50%;
  z-index: 9999;
}

.audio_start {
  cursor: pointer;
  position: relative;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: block;
  text-align: center;
  padding: 4px 0px 0px 0px;
  transition: all 0.3s ease;
  color: #2e2e2a;
  background: #ffc107;
  font-size: 20px;
}
.audio_start1 {
  position: relative;
}
.header-top-wrapper-bottom.d-flex {
  position: relative;
  top: 34px;
  left: -12px;
}
.right-div-section-bottom img {
  width: 112px;
  height: 108px;
}
.header-section-bottom {
  position: absolute;
  width: 341px;
}

@media(max-width:1024px){
  .header-section-tow{
    width: 39%;
  }
}
@media (max-width: 967px){
.header-section-tow {
    width: 43%;
}
}
@media (max-width: 881px){
.header-section-tow {
    width: 48%;
}
}
@media (max-width: 769px){
.header-section-tow {
    width: 55%;
}}
@media (max-width: 667px){
.header-section-tow {
    width: 70%;
}}
@media (max-width: 514px){
.header-section-tow {
    width: 80%;
}
}
@media (max-width: 414px){
.header-section-tow {
    width: 90%;
}
}


.color-item {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 0 0px 0px 10px;
  position: relative !important;
  z-index: 1;
  transition: transform .2s ease;
  flex-shrink: 0;

}

/* .color-item:hover {
  cursor: pointer;
  border: 2px solid #f1f1f1;
} */
.color-chooser{
  display: flex;
  cursor: pointer;
}
.color-item-selected, .color-item-deletable {
  border: 3px solid #f0b503;
  transform: scale(1.1);
}
.color-item-selected
.color-item-deletab::after {
  content: 'hii'!important;
  position: absolute !important;
  top: 50% !important;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  color: #fff;
}
 
.color-item-selected .color-item-deletable::after {
      content: '✖';
      display: none;
  }

  .color-item-selected.color-item-deletable:hover::after {
      display: block;
  }



  .color-item-1 {
    width: 36px;
    height: 36px;
    text-align: center;
    font-size: 14px;
    padding: 5px 2px 2px 2px;
    border-radius: 50%;
    border: 1px solid #000;
    margin: 0 0px 0px 10px;
    position: relative !important;
    z-index: 1;
    transition: transform .2s ease;
    flex-shrink: 0;
    background-color: transparent;
    color: #000;
}
  
  .color-item-selected-1, .color-item-deletable-1 {
    border: 2px solid #2874f0;
    transform: scale(1.1);
    color:#fff;
    background-color:#2874f0 ;
  }
 
   
  .icon-color-size {
    font-size: 18px;
    color: #fff;
    text-align: center;
    padding: 3px 0px;
    margin: 3px 0px 0px 3px;
    font-weight: 700 !important;
}
.error-selected {
    color: #000;
    font-size: 13px;
    text-align: center;
}

.icon-hide-logout{
  display: none !important;
  visibility: hidden !important;
  margin-right: 0px;
}

/* .chat-fun{
  background: transparent;
} */

/* .chat-input-icon{
  color: #fff;
  font-size: 20px;
} */

/* .chat-input-icon {
  margin-bottom: -11px;
  color: #fff;
  font-size: 20px;
  margin-right: 24px;
  margin-top: 23px;
} */
/* .chat-fun ul {
  display: flex;
  margin: 0 auto;
  text-align: center;
  align-items: center;
} */
/* .chat-fun {
  display: flex;
  background: transparent;
} */
.popover {
  position: fixed;
  top: 235px;
}
/* .chat-section{
  height: 517px;
  background-image: linear-gradient(180deg, #3e030e, #321a2ee6);
  border-radius: 5px;
} */
/* @media(max-width:1440px){
  .chat-section {
    width: 321px;
    margin-left: -11px;
    height: 461px;
    background-image: linear-gradient(180deg, #3e030e, #321a2ee6);
    border-radius: 5px;
    margin-top: -130px;
}
} */
/* img.gif-text {
  width: 55px;
  height: 55px;
  object-fit: cover;
} */
.color {
  margin-top: 5px;
  /* height: 20px;
  border-radius: 50% !important;
  width: 20px;  */
  /* margin-right: 19px; */
  /* padding-bottom: -64px; */
  /* margin-bottom: 2px; */
  /* border-radius: 3px; */
  /* background: rgb(49, 88, 6); */
} 

.swatch  {
  /* padding: 9px; */
    /* background: rgb(255 255 255); */
    border-radius: 50%;
    height: 20px;
    width: 20px;
    cursor: pointer;
    display: inline-block;
    /* margin-bottom: -13px;*/
}
.view-story-page{
  /* display: flex; */
  overflow-x: hidden;
}
.view-div-story{
  width: 100%;
  height: 500px;
}
.view-div-story .react-player .react-player__preview{
  border-radius: 5px !important;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.view-div-story img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.view-icon-story ul li{
  font-size: 20px;
    color: #fff;
    padding: 0px 20px 0px 0px;
    cursor: pointer;
    font-weight: 200;
}
.view-story-pra p{
  color: #fff;
    font-size: 13px;
    font-weight: 200;
    margin-bottom: 0px;
}
.view-div-story .react-player__shadow{
  border: 6px solid #fff;
    background: radial-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 60%);
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}
.count-div {
  /* background: red; */
  /* font-size: 16px; */
  position: absolute;
  top: 92px;
  right: 0px;
  z-index: 9;
  background: rgb(0 0 0 / 50%);
}
.count-div p{
  font-size: 20px;
  color: #fff;
}

.imgdefault {
  width: 38px;
  height: 38px;
  border-radius: 19px;
}

.imgdefault-div {
  width: 47px;
    height: 47px;
    border-radius: 50%;
}
.emoji-input {
  display: flex;
  width: 100%;
  position: relative;
  color: #fff;
  font-size: 15px;
}
.form {
  display: flex;
  background-color: transparent !important;
  padding: 0px !important;
  border-radius: 8px;
  color: #fff;
  margin: 10px 0px;
}
.form .userImg {
  background-color: gray;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: none;
  margin: 0px 0px !important;
}
.comment-title {
  font-family: 'Noto Sans', sans-serif;
  font-size: 30px;
  font-weight: 700;
  color: #202020d1;
  display: none;
  visibility: hidden;
}
.hr-style {
  width: 100%;
  border-top: 1px solid #cbc4c4 !important;
}

  .commentsTwo {
  display: flex;
  align-items: center;
  /* margin-top: 8px; */
  color: #fff;
} 
.infoStyle {
  margin-left: 36px;
  font-size: 15px;
  color: #fff;
}

.form .postBtn-div {
  border: 2px solid #ffc107 !important;
  border-radius: 8px;
  background-color: transparent !important;
  padding: 5px 10px;
  color: white;
  font-weight: bolder;
  margin-left: 15px;
  font-size: 16px;
  cursor: pointer;
  padding: 5px 20px;
}
.halfDiv-div{
  display: flex;
}
.read-comment{
  width: 300px;
  background-color: #fff;
  border-radius: 11px;
  margin-left: 5px;
  padding: 2px 8px;
  /* margin-bottom: 35px;  */
}
.read-comment p{
  font-size: 11px;
}
.read-comment h5 {
  font-size: 11px;
  color: #000;
  font-weight: 700;
}
.comment-date{
  float: right;
  font-size: 9px;
  font-weight: 400 !important;
  color: #000000b8;
}
.form .postBtn-div:hover{
  border: none !important;
  background-color: #ffc107 !important;
}
.replyIcon {
  /* background-image: url(http://localhost:3000/soundchatradio/static/media/2a0bc34f2fdf6d6b.6e0f8a4….svg); */
  width: 16px;
  height: 13px;
  filter: invert(67%) sepia(0%) saturate(0%) hue-rotate(110deg) brightness(85%) contrast(84%);
  margin-right: 5px;
  position: absolute;
  margin-top: 5px;
}
.overlay {
  display: flex;
  flex-direction: column;
  padding: 0px !important;
  font-family: 'Noto Sans', sans-serif;
}

.left-sid-div {
  /* width: 100%; */
  padding: 15px 21px 0px 0px;
  /* height: 500px; */
}
.like-comment{
  margin-left: 5px;
  font-size: 17px;
}


@media(max-width:359px){
  .view-div-story {
    width: 100% !important;
    height: 170px !important;
    padding: 0px !important;
  }
  .view-story-pra p {
    color: #fff !important;
    font-size: 12px !important;
    padding: 0px 12px !important;
    margin-bottom: 0px !important;
}
.view-div-story img {
  object-fit: cover !important;
  width: 100% !important;
  height: 162px !important;
  overflow: hidden !important;
}
.overlay-div-comment {
  display: flex !important;
  flex-direction: column !important;
  padding: 0px !important;
  margin-top: 44px;
    position: relative;
    height: 100%;
    background: #000 !important;
}
.form {
  display: flex !important;
  /* background-color: rgb(243, 243, 243) !important; */
  padding: 9px !important;
  border-radius: 8px !important;
}
.left-sid-div {
  padding: 0px !important;
}
.view-icon-story ul li {
  font-size: 20px !important;
  color: #fff !important;
  /* margin-right: 0px; */
  padding: 0px 7px !important;
}
  .story-video>.react-player__play-icon{
  border-style: solid !important;
  border-width: 10px 0px 10px 16px !important;
  border-color: transparent transparent transparent white !important;
  margin-left: 7px !important;
  }
  .story-video .react-player .react-player__preview .react-player__shadow{
    background: radial-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 60%) !important;
    border-radius: 30px !important;
    width: 47px !important;
    height: 47px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  }

  /* @media(max-width:1440px){
    svg.chaticon {
      position: fixed;
      font-size: 40px;
      color: red;
      z-index: 9999;
      right: 0px;
      top: 50%;
      display: none;
     
  } */
  /* } */


  /* .chaticon-mobile{
    position: fixed;
    font-size: 40px;
    color: red;
    z-index: 9999;
    right: 0px;
    top: 50%;
    display: none;
  }
  @media(max-width:575px){
    .chaticon-mobile {
      position: fixed !important;
      font-size: 32px !important;
      color: #ffc107;
      z-index: 9999 !important;
      right: 0px !important;
      top: 86% !important;
      display: block !important;
    }
    } */

  /* @media(max-width:575px){
    .chat-section {
      display: none;
      visibility: hidden;
  }

} */




@media(max-width:326px){
p.todu {
  color: #d9edf7 !important;
  margin-left: 8px !important;
}
}










.chatting-new-window-newchat {
  position: relative;
  background-image: url(https://soundchatradio.com/wp-content/uploads/2021/04/sc-footer-bgn2.jpg);
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100% !important;
}
.msger-newchat {
  width: 100%;
  position: relative;
  margin: 10px 0px;

}
.msger-header-newchat {
  display: flex;
  font-size: 15px;
  justify-content: space-between;
  padding: 7px;
  border-bottom: var(--border);
  background: #eee;
  color: #666;
}
.msger-chat-newchat {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}
.msger-chat-newchat::-webkit-scrollbar {
  width: 6px;
}
.msger-chat-newchat::-webkit-scrollbar-track {
  background: #ddd;
}
.msger-chat-newchat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.msg-newchat {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}
.msg-newchat:last-of-type {
  margin: 0;
}
h6.send-plus-newchat {
  font-size: 16px;
  padding-top: 12px;
  padding-right: 9px;
  color: #000;
}
.msg-bubble-newchat {
  max-width: 450px;
  padding: 15px;
  border-radius: 15px;
  background:#fff;
}
span.emoji-newchat {
  font-size: 15px;
  padding-top: 5px;
  color: #ffc413;
  padding-right: 10px;
}
.btn--send-newchat {
  display: flex !important;
  border-radius: 3px;
  position: absolute;
  right: 0px;
}
.msg-info-newchat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
  font-size: 14px;
}
.msg-text-newchat {
  font-size: 12px;
}


.msg-info-name-newchat {
  margin-right: 10px;
  font-weight: bold;
}
.msg-info-time-newchat {
    text-align: right;
    font-size: 0.85em;
    color: #ffffff9c;
}
.left-msg-newchat .msg-bubble-newchat {
  border-bottom-left-radius: 0;
}


.right-msg-newchat {
  flex-direction: row-reverse;
}
.right-msg-newchat .msg-bubble-newchat {
  background: #a8aeb1;
  color: #000;
  border-bottom-right-radius: 0;
}
h5.send-bn-newchat {
  color: #fff;
  padding-top: 4px;
  padding-right: 11px;
  font-size: 18px;
}
.msg-chatt-footer-newchat {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.8rem;
  border-top: 1px solid #e0e0e0;
  background-color: whitesmoke;
}
.msg-chatt-text-newchat {
  width: 100%;
  padding: 13px;
  font-size: 11px;
  color: #fff !important;
  background-color: #080808;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
}
.btn--send-newchat {
  border-radius: 3px;
}
.msger-chat-newchat {
  padding: 20px;
    height: 506px;
    width: 100%;
    background: #020202;
} 

.chat-input-icon-newchat {
  margin-bottom: 7px;
  color: #fff;
  font-size: 20px;
  margin-right: 24px;
  margin-top: 23px;
}
.chat-fun-newchat ul {
  display: flex;
  margin-left: 35%;
  margin-top: -22px;
  margin-bottom: 0px !important;
  /* text-align: center;
  align-items: center; */
}
.chat-fun-newchat {
  background: #020202;
}
@media(max-width:991px){
  .offset-2-newchat {
    margin-left: 0 !important;
  }
}
.offset-2-newchat.col-lg-8.col-md-12.col-sm-12 {
  margin-left: 150px;
}


@media(max-width:1440px){
  .header-menu-1-div-icon {
    display: none !important;
}
}
@media(max-width:1349px){
  .header-menu-1-div-icon {
    display: none !important;
}
svg.login-icon-div {
  display: none !important;
}
svg.login-icon-div {
  display: none !important;
}
}
@media only screen and (min-width: 576px) and (max-width: 991px)  {
  svg.login-icon-div {
    display: block !important;
  }
.header-menu-1-div-icon{
  font-size: 21px !important;
  color: #fff !important;
  margin-right: 13px !important;
  display: block!important;
  margin-top: 8px !important;
}
}
  /* @media(min-width:575px){
    svg.login-icon-div {
      display: block !important;
    }
  .header-menu-1-div-icon{
    font-size: 21px !important;
    color: #fff !important;
    margin-right: 13px !important;
    display: block!important;
    margin-top: 8px !important;
}
} */

 @media(max-width:575px){    
.header-menu-1-div-icon{
  font-size: 21px !important;
  color: #fff !important;
  margin-right: 13px !important;
  display: block!important;
  margin-top: 8px;
}
svg.login-icon-div {
  display: block !important;
}
}

    
    
/* .chat-user-div{
  height: 457px;
    background-image: linear-gradient(180deg, #3e030e, #321a2ee6);
    border-radius: 5px;
} */



/* @media (max-width: 1440px){
  .chat-user-div{
  height: 462px !important;
  background-image: linear-gradient(180deg, #3e030e, #321a2ee6) !important;
  border-radius: 5px !important;
  text-align: center !important;
}
} */


 /* svg.chaticon {
    display: none !important;

} */
.header-menu-1-div-icon {
  display: none;
}

.reply-comment {
  display: block !important;
  margin: 0px auto !important;
  position: relative;
    left: 50px;
 
}
.reply-comment .reply-text {
  color: #fdf0f0c7;
  margin-top: 12px;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
  position: relative;
  left: 186px;
  font-weight: 300;
  font-size: 11px;
}

/* .scrolling-comment {
  overflow: auto !important ;
  height: 368px !important;
}
.scrolling-comment ::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3) !important;
	border-radius: 10px !important;
	background-color: #F5F5F5 !important;
}

.scrolling-comment ::-webkit-scrollbar
{
	width: 12px !important;
	background-color: #F5F5F5 !important;
}

.overlay.scrolling-comment ::-webkit-scrollbar-thumb
{
	border-radius: 10px !important;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3) !important;
	background-color: #D62929 !important;
} */



.sidebar {
  position: relative;
  height: 100%;
  background: #fff;
}

.scrolling-comment {
  overflow: auto;
  height: 400px;
  margin-right: 2px;
}
.scrolling-comment::-webkit-scrollbar
{
  width: 5px;
  background-color: #000 !important;
border-radius: 50px;
}



.reply-comment-box{
  display: flex;
  margin-bottom: 10px;
}
.postBtn-reply{
  border: 1px solid #c59810;
  color: #fff;
  border-radius: 4px;
  width: 55px;
  height: 27px;
  text-align: center;
  padding: 5px 0px;
  margin-left: 13px;
}
input.postCommentreply {
  color: #fff;
  background-color: transparent;
  border: none;
  width: 250px;
  border-bottom: 1px solid #dacdcd1a;
  margin-left: 5px;
}
.listing-reply-img img{
  width: 39px;
  height: 38px;
  background-color: #f0f3f7d9;
  border-radius: 50%;
}
.listing-reply-comment{
  display: flex;
  margin-bottom: 10px;
}

.read-replycomment{
  background-color: #fff;
    width: 217px;
    border-radius: 5px;
    margin-left: 7px;
    padding: 4px 6px;
}
span.reply-date {
  font-size: 9px;
  float: right;
}
.emoji-input-reply {
  position: relative;
  top: 12px;
}
.comment-list-img {
  background-color: #ffffffb8;
  border-radius: 50%;
  width: 47px;
  height: 46px;
}
.reply-count-div{
  margin-left: 5px;
  font-size: 11px;
  color: #2383d6;
  font-weight: 300;

}

.podcast-div {
  background: #fff;
  box-shadow: 1px 2px 7px 2px #c0baba;
  min-height: 163px;
  margin-bottom: 15px;
  border-radius: 5px;
  padding: 12px 7px;
  text-align: center;
  margin-top: 10px;
}


.tab-click .owl-nav button {
  width: 35px;
    height: 35px;
    text-align: center;
    display: flex !important;
    align-items: center;
    background: #fff !important;
    border-radius: 50px !important;
    color: #9f9e9e !important;
    font-size: 30px !important;
    padding: 0 !important;
    box-shadow: 1px 2px 7px 2px #c0baba;
    /* margin: 0 !important; */
    justify-content: center;
}
.tab-click  .owl-nav {
  display: flex;
  position: absolute;
  top: -60px;
  right: 0;
}
/*************view story pages css ********************/
.story-page-container{
  width: 100%;
  height: 500px;
  overflow-x: hidden;
}
.story-view{
  width: 100%;
    height: 500px;
    overflow: hidden;
    background-size: cover;
    background-position: center center;  
}
.story-p-right{
  padding: 7px 20px 0px 0px;
}

.story-p-left{
  padding: 7px 0px 0px 20px;
}
.img-gallery-count{
  float: right;
  position: absolute;
  right: 48px;
  font-size: 44px;
  color: #fff;
  top: 381px;
}
.story-video .react-player .react-player__preview{
  border-radius: 5px !important;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.story-video .react-player .react-player__preview .react-player__shadow{
  border: 6px solid #fff;
  background: radial-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 60%);
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .story-view{
    width: 100%;
    height: 300px;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
  }
  .story-p-left{
    padding: 7px 18px 0px 18px !important;
  }
  .story-p-right{
    padding: 7px 18px 0px 18px !important;  }
}
@media only screen and (max-width: 767px) and (min-width: 575px) {
  .story-video .react-player .react-player__preview .react-player__shadow{
    margin-bottom: 203px !important;
    border: 6px solid #fff;
    background: radial-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0) 60%);
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
}