.chat-user {
  height: 462px;
  background-image: linear-gradient(180deg, #3e030e, #321a2ee6);
  border-radius: 5px;
  text-align: center;
  padding-top: 130px !important;
  padding: 10px;
}
@media only screen and (max-width: 767px) and (min-width: 320px) {
  .chat-user {
    height: 382px;
    padding-top: 84px;
    margin-top: 10px;
  }
  .chat-secound{
    display: none;
  }
}

/* @media(max-width:1440px){ */
/* .chat-user {
        height: 462px;
        background-image: linear-gradient(180deg, #3e030e, #321a2ee6);
        border-radius: 5px;
        text-align: center;
        padding-top: 130px;
    } */
/* }    */
.btn-div-chat-login {
  width: 100%;
  font-weight: 600;
  padding: 9px 0px;
  border: none;
  background-color: #808080a3;
  color: white;
  border-radius: 5px;
  font-size: 14px;
}
.div-or {
  color: white;
  font-size: 17px;
}
.has-search input {
  width: 100%;
  height: 49px;
  border-radius: 26px;
  padding-left: 6.375rem;
  border: 2px solid white;
  font-size: 13px;
  background-color: transparent;
  color: white !important;
}

.has-search .user-icon-div {
  position: absolute;
  margin-top: 2px;
  left: 19px !important;
  font-size: 26px;
  border-radius: 50%;
  background-color: #c59ca0;
  width: 45px;
  height: 45px;
  color: #813a41;
}

.new-user-chat-010 {
  margin-top: 37px;
}

/**************************chat ka login section end ***********************/
/**********************************************************************************/

.chat-section {
  height: 517px;
  background-image: linear-gradient(180deg, #3e030e, #321a2ee6);
  border-radius: 5px;
}
@media (max-width: 1440px) {
  .chat-section {
    width: 321px;
    /* margin-left: -11px; */
    height: 461px;
    background-image: linear-gradient(180deg, #3e030e, #321a2ee6);
    border-radius: 5px;
    /* margin-top: -130px; */
  }
}
@media (max-width: 767px) {
  .chat-section {
    display: none;
    visibility: hidden;
  }
}

.msger {
  width: 100%;
  position: relative;
}
.msger-header {
  display: flex;
  font-size: 15px;
  justify-content: space-between;
  padding: 7px;
  border-bottom: var(--border);
  background: #eee;
  color: #666;
  border-radius: 3px;
}

.msger-chat {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
  height: 317px;
  width: 100%;
  background: transparent;
}

.msger-chat::-webkit-scrollbar {
  width: 6px;
}
.msger-chat::-webkit-scrollbar-track {
  background: transparent;
}
.msger-chat::-webkit-scrollbar-thumb {
  background: #383131;
}
.msg {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}
.msg:last-of-type {
  margin: 0;
}
.left-msg .msg-bubble {
  border-bottom-left-radius: 0;
}
.msg-bubble {
  max-width: 450px;
  padding: 15px;
  border-radius: 15px;
}
.msg-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
  font-size: 14px;
}
.msg-info-name {
  margin-right: 10px;
  font-weight: bold;
}
.msg-text {
  font-size: 12px;
}
img.gif-text {
  width: 55px;
  height: 55px;
  object-fit: cover;
}
.msg-info-time {
  font-size: 0.85em;
  text-align: right;
  color: #ffffffa6;
}
.right-msg {
  flex-direction: row-reverse;
}
.right-msg .msg-bubble {
  background: #a8aeb1;
  color: #000;
  border-bottom-right-radius: 0;
}
.chat-fun {
  background: transparent;
  display: flex;
  background: transparent;
}
.chat-fun ul {
  display: flex;
  margin: 0 auto;
  text-align: center;
  align-items: center;
}
.chat-input-icon {
  margin-bottom: -11px;
  color: #fff;
  font-size: 20px;
  margin-right: 24px;
  margin-top: 23px;
  cursor: pointer;
}
.msg-chatt-footer {
  display: flex;
  margin-top: 14px;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 4px;
  background-color: transparent;
}
.msg-chatt-text {
  width: 100%;
  padding: 13px;
  font-size: 11px;
  color: #fff !important;
  background-color: #080808;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
}
.btn--send {
  border-radius: 3px;
}
span.emoji {
  font-size: 15px;
  padding-top: 5px;
  color: #ffc413;
  padding-right: 10px;
}
.btn--send {
  display: flex !important;
  border-radius: 3px;
  position: absolute;
  right: 0px;
}
h5.send-bn {
  color: #fff;
  padding-top: 4px;
  padding-right: 11px;
  font-size: 18px;
}
span.logoutchat {
  /* float: right; */
  /* margin: auto 0px; */
  position: relative;
  left: 220px;
  cursor: pointer;
}

/* *********************************************************************************** */
.chaticon-mobile{
  position: fixed;
  font-size: 40px;
  color: red;
  z-index: 9999;
  right: 0px;
  top: 50%;
  display: none;
}
@media(max-width:767px){
  .chaticon-mobile {
    position: fixed !important;
    font-size: 32px !important;
    color: #ffc107;
    z-index: 9999 !important;
    right: 0px !important;
    top: 86% !important;
    display: block !important;
  }
  }