body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* chatting */
/*====> Chat CSS <=====*/
 /* .chatting-new-window-newchat {
  position: relative;
  background-image: url(https://soundchatradio.com/wp-content/uploads/2021/04/sc-footer-bgn2.jpg);
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100% !important;
}
.msger-newchat {
  width: 100%;
  position: relative;
  margin: 25px 0px;

}
.msger-header-newchat {
  display: flex;
  font-size: 15px;
  justify-content: space-between;
  padding: 14px;
  border-bottom: var(--border);
  background: #eee;
  color: #666;
}
.msger-chat-newchat {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}
.msger-chat-newchat::-webkit-scrollbar {
  width: 6px;
}
.msger-chat-newchat::-webkit-scrollbar-track {
  background: #ddd;
}
.msger-chat-newchat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.msg-newchat {
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}
.msg-newchat:last-of-type {
  margin: 0;
}
h6.send-plus-newchat {
  font-size: 16px;
  padding-top: 12px;
  padding-right: 9px;
  color: #000;
}
.msg-bubble-newchat {
  max-width: 450px;
  padding: 15px;
  border-radius: 15px;
  background:#fff;
}
span.emoji-newchat {
  font-size: 15px;
  padding-top: 5px;
  color: #ffc413;
  padding-right: 10px;
}
.btn--send-newchat {
  display: flex !important;
  border-radius: 3px;
  position: absolute;
  right: 0px;
}
.msg-info-newchat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0px;
  font-size: 14px;
}
.msg-text-newchat {
  font-size: 12px;
}


.msg-info-name-newchat {
  margin-right: 10px;
  font-weight: bold;
}
.msg-info-time-newchat {
  font-size: 0.85em;
}
.left-msg-newchat .msg-bubble-newchat {
  border-bottom-left-radius: 0;
}

.right-msg-newchat {
  flex-direction: row-reverse;
}
.right-msg-newchat .msg-bubble-newchat {
  background: #a8aeb1;
  color: #000;
  border-bottom-right-radius: 0;
}
h5.send-bn-newchat {
  color: #fff;
  padding-top: 4px;
  padding-right: 11px;
  font-size: 18px;
}
.msg-chatt-footer-newchat {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.8rem;
  border-top: 1px solid #e0e0e0;
  background-color: whitesmoke;
}
.msg-chatt-text-newchat {
  width: 100%;
  padding: 13px;
  font-size: 11px;
  color: #fff !important;
  background-color: #080808;
  border-radius: 20px;
  border: 1px solid #e0e0e0;
}
.btn--send-newchat {
  border-radius: 3px;
}
.msger-chat-newchat {
  padding: 20px;
    height: 506px;
    width: 100%;
    background: #020202;
}  */